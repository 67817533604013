import React from 'react'
import RuppeIcon from "../assets/ruppeicon.png"
import { generatePath } from 'react-router-dom'
import { showImgInSwal } from '../Components/ShowAlertCustome';

export default function LagahistoryCard({ laga }) {
    const { lagaId, lagaYear, recMembershipCode, Amount, isLagaPaid, paymentDateTime } = laga;

    const onPayHandler = () => {
        showImgInSwal("Scan Qr To Pay Laga", 'https://api.qrserver.com/v1/create-qr-code/?size=225x225&data=upi%3A%2F%2Fpay%3Fcu%3DINR')
    }
    return (


        // entryIdAauto generateP
        // membercode paidby null,
        // Amount min 100,
        // payment Date same datefor mayment null,
        // laga year not null,
        // recEMemberCode session null,
        // lagaSatus notPaid,

        // view
        // lagaYear ,
        // amount,
        // paymenyDate,
        // status id not paid then may now amont paid ,


        // "_id": "6656cd65ef323d6f5a1abffc",
        // "lagaId": 896,
        // "paymentDateTime": null,
        // "registrationMobileNumber": "7709433561",
        // "memberName": "भोई मयूर",
        // "isLagaPaid": 0,
        // "membershipCode": "MEM00911",
        // "recMembershipCode": null,
        // "Amount": null,
        // "lagaYear": 2024,
        // "__v": 0,
        // "createdAt": "2024-05-29T06:38:29.312Z",
        // "updatedAt": "2024-05-29T06:38:29.312Z"



        <div className='shadow-all-sides flex items-center flex-col w-[300px] overflow-hidden   h-[100%] shadow-2xl  rounded-xl'>
            <div className={`flex text-white font-extrabold text-[1.2rem] justify-center items-center  ${isLagaPaid ? 'bg-green-500' : 'bg-orange-600'}   w-[350px] h-[40px]`}>लागा वर्ष {lagaYear}
            </div>
            <div className=' ms-2 flex  '>
                <div className=' w-[300px] text-black  px-4  my-2 flex flex-col justify-between '>
                    <div className='flex w-full justify-between rounded-3xl px-2   text-white'>
                        <p className='text-black font-bold'>लागा आईडी <span className=' ms-8'>:</span> </p>
                        <div className=' flex w-20  rounded-3xl px-2   '>
                            <p className=' text-black  '>{lagaId}</p>
                        </div>
                    </div>

                    <div className='flex w-full justify-between rounded-3xl  px-2  text-white'>

                        <p className='text-black font-bold '>लागा राशि <span className=' ms-11'>:</span></p>
                        <div className=' flex w-20  rounded-3xl px-2   '>
                            {/* <img className='w-5 h-5 text-black' src={RuppeIcon} /> */}
                            <p className=' text-black ' > {Amount}</p>
                        </div>
                    </div>

                    <div className='flex w-full justify-between rounded-3xl  px-2  text-white'>

                        <p className='text-black font-bold'>भुगतान दिनांक <span className=' ms-3'>:</span></p>
                        <div className=' flex w-20 text-black rounded-3xl px-2  '>

                            <p className='text-black' > {paymentDateTime && new Date(paymentDateTime).toLocaleDateString()}</p>
                        </div>
                    </div>

                    <div className='flex w-full justify-between rounded-3xl my-1 px-2  text-white'>


                        <>
                            <p className='text-black font-bold'>लागा स्तिथी <span className=' ms-9'>:</span></p>
                            <div className=' flex w-20  rounded-3xl px-2   '>
                                {/* <img className='w-5 h-5' src={RuppeIcon} /> */}
                                <p className='text-black' >{isLagaPaid ? 'Paid' : 'Not Paid'}</p>
                            </div>
                        </>


                        {/* </> :

                            <>
                                <p className='text-black font-bold'>Amount <span className=' ms-8'>:</span></p>
                                <div className=' flex w-20 bg-orange-400 rounded-3xl px-2  text-white '>
                                    <img className='w-5 h-5' src={RuppeIcon} />
                                    <p > {Amount}</p>
                                </div></>
                        } */}


                    </div>

                    {!isLagaPaid && <div className='flex w-full justify-center rounded-3xl px-2 my-1  text-white'>

                        <button onClick={onPayHandler} className=' cursor-pointer bg-blue-950 rounded-3xl px-4 py-1 text-center font-extrabold  text-white '>भुगतान करें</button>
                    </div>}
                </div>
            </div>
        </div >
    )
}



{/* <a target="_blank" href="https://icons8.com/icon/msHsIqNfSIC2/rupee">Rupee</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */ }