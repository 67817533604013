import React, { useState } from 'react'
import { CommitteeCard, MembersCard } from '../../../Components'
import UserData from "../../../fakeData/UserData.js";
import Card from '../../../Components/Card.js';
import Form from "../../../Forms.js/Criterial.js"
import CommitteeMembers from "../../../fakeData/commeteeFake.js"


export default function Committee() {
    const [CommitteeMember, setCommitteeMembers] = useState(CommitteeMembers);
    const data = ['Apple', 'Banana', 'Orange', 'Grapes', 'Mango', 'Pineapple'];


    return (
        <div className='    flex flex-wrap gap-3 justify-center '>

            <div className='flex flex-wrap justify-around mt-5 p-3   w-[100vw] bg-[#151B54]'>
                {/* <Form data={data} label={"सदस्य का नाम"} />
                <Form data={data} label={"समाज मैं पद"} />
                <Form data={data} label={"मोबाइल नंबर"} /> */}
                {/* <Form data={data} label={"lable"} /> */}
            </div>



            <div className='    flex flex-wrap  gap-0 sm:gap-3  justify-center '>
                {/* <CommitteeCard /> */}

                {CommitteeMember.map((member, index) =>
                (
                    <CommitteeCard Committeemember={member} key={index} />
                )
                )}

            </div>
            {/* {members.map((member, index) =>
            (
                <MembersCard user={member} key={index} />
            )
            )} */}
        </div>
    )
}
