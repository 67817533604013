import React, { useEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { HeaderBoarder } from '../Components'
import { useForm } from 'react-hook-form';
import { Input, SelectInput, Textarea } from '../FormsInputes.js';
import Swal from 'sweetalert2';
import { addSuggession } from '../service/Services.js';
import AdminButton from './AdminButtons.js';
const typeOfSuggestion = [
    "कमिटी",
    "मेंबर",
    "असुविधा",
    "कार्यक्रम",
    "धर्मशाला बुकिंग",
    "डोनेशन",
    "लागा पेमेंट",
    "शादी रिश्ते",
    "मोबाइल ऐप",
    "मंदिर",
    "निमंत्रण"
];


export default function Suzhavform() {
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({
        suggesstionsFor: "कमिटी",
        suggesstions: "",
        samajToShow: [],
        entryInSamaj: {}
    });
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)

    useEffect(() => {
        setValue('samajToShow', [{ text: 'porwal samaj indor', id: 1 },])
        setValue('entryInSamaj', {
            curentSamaj: "porwal samaj indor",
            id: 1
        })
    }, [])

    const submit = async (data) => {


        console.log(data)


        try {
            const requiredFields = ['suggesstionsFor', 'suggesstions',];
            const hasMissingFields = requiredFields.some(field => !data[field]);
            if (hasMissingFields) {
                throw new Error("All Star fields are required");
            }
            setLoading(true);
            console.log(data)
            // Create FormData object
            // const formData = new FormData();
            // const entryInSamaj = {
            //     curentSamaj: "Indor porwal samaj",
            //     id: 1
            // }
            // formData.append('achievementHeading', data.achievementHeading);
            // formData.append('samajToShow', JSON.stringify(samajShow));
            // formData.append('entryInSamaj', JSON.stringify(entryInSamaj));
            // formData.append('achiverName', data.achiverName);
            // formData.append('message', data.message);
            // formData.append('profilePhoto', data.profilePhoto);
            // formData.append('photo1', data?.photo1);
            // formData.append('photo2', data?.photo2);




            // Call addNews API
            const isNewsAdded = await addSuggession(data);

            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()



            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    // addSuggession
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };
    return (
        <>
            <HeaderBoarder heading={"सुझाव"} />
            <div className='w-100  md:px-10 '>


                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'suggestions / सुझाव'} />

                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* suggesstions */}
                        <div className="sm:col-span-3">
                            <SelectInput
                                label="Suggestion category / सुझाव श्रेणी"
                                placeholder="Suggestion category / सुझाव श्रेणी"
                                options={typeOfSuggestion}
                                required="required"
                                {...register("suggesstionsFor", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                })}
                                onChange={(e) => handleChange("suggesstionsFor", e)}
                            // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                            />
                            {errors.suggesstionsFor && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.suggesstionsFor.message}</p>}
                        </div>
                        <div className="sm:col-span-3">
                            <Input
                                label="Date / एन्ट्री तारीख"
                                placeholder="एन्ट्री तारीख"
                                type="text"
                                required="required"
                                value={new Date().toLocaleString()}
                                disabled

                            />
                            {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>}
                        </div>
                        <div className="col-span-full mx-2 ">

                            <Textarea
                                label="suggesstions / सुझाव"
                                placeholder="suggesstions"
                                type="text"
                                {...register("suggesstions", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    // value: `${formData?.permanentAddress}`,
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 20 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 2000,
                                        message: "कृपया 2000 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("suggesstions", e)}
                            />
                            {errors.suggesstions && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.suggesstions.message}</p>}

                        </div>

                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>

                </form>
            </div>
        </>
    )
}
// suggesstions entry id-