import React from 'react'
import { ComingSoon } from '../../../Components'
import ImageCarousel from '../../../Components/carousel'

export default function Businessprofile() {
    return (
        <div>
            {/* <div className="flex flex-col items-center justify-center   bg-gray-100">
                <ImageCarousel />
            </div> */}
            <ComingSoon />
        </div>
    )
}
