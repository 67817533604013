import React, { useEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { useForm } from 'react-hook-form';
import { CheackBox, Input, InputDate, PhotoUpload, SelectInput, Textarea } from '../FormsInputes.js'
import Swal from 'sweetalert2';
import { addDonation } from '../service/Services.js';
import { HeaderBoarder } from '../Components/index.js';
import { gotra } from "../constants/staticData.js"
import AdminButton from './AdminButtons.js';

const formatDate = (date) => {
    return date.toLocaleString();
};

export default function DonationForm() {
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const [isBankTransferChecked, setIsBankTransferChecked] = useState(false);
    const [isUpiTransferChecked, setIsUpiTransferChecked] = useState(false);
    const [isChequeTransferChecked, setIsChequeTransferChecked] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();
    const [todayDate, setTodayDate] = useState();

    useEffect(() => {
        const today = new Date();
        setTodayDate(formatDate(today))
    }, [])

    setValue("donationDate", todayDate)
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };
    const handleFileChange = (fimeName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fimeName, file);
    };

    const submit = async (data) => {
        // eventPhoto2

        console.log(data)
        try {
            const requiredFields = ['donationDate', 'samajMemberCode', 'donarName', 'donarMobileNumber',
                'donarCity', 'donarAddress', 'donationAmount', 'donationReciveDate', 'donationReciverMembershipCode', 'remark', 'donarPhoto',
            ];
            const hasMissingFields = requiredFields.some(field => !data[field]);
            if (hasMissingFields) {
                throw new Error("All Star fields are required");
            }

            const donationCategory = {
                samajMemberLaga: data.samajMemberLaga,
                contributionAmount: data.contributionAmount,
                agreedAmount: data.agreedAmount,
                donation: data.donation,
                other: data.other,
            };
            const donationCategoryArray = [];
            for (const key in donationCategory) {
                if (donationCategory[key]) {
                    donationCategoryArray.push(key);
                }
            }

            console.log(donationCategoryArray)

            if (donationCategoryArray.length === 0) {
                throw Error("Choose Atleast One Category")
            }
            setLoading(true);
            console.log(data)
            // Create FormData object
            const formData = new FormData();

            formData.append('donationDate', data.donationDate);
            formData.append('samajMemberCode', data.samajMemberCode);
            formData.append('donarName', data.donarName);
            formData.append('donarMobileNumber', data.donarMobileNumber);
            formData.append('donarCity', data.donarCity);
            formData.append('donarAddress', data.donarAddress);
            formData.append('donationAmount', data?.donationAmount);
            formData.append('donationReciveDate', data.donationReciveDate);
            formData.append('donationReciverMembershipCode', data.donationReciverMembershipCode);
            formData.append('donationCategory', JSON.stringify(donationCategoryArray));

            formData.append('remark', data.remark);
            formData.append('donarPhoto', data?.donarPhoto);
            formData.append('eventPhoto1', data?.eventPhoto1);
            formData.append('eventPhoto2', data?.eventPhoto2);

            // Call addNews API
            const isNewsAdded = await addDonation(formData);
            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                // reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);

        }
    };

    return (
        <>
            <HeaderBoarder heading={"डोनेशन"} />


            <div className='w-100  md:px-10 '>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    {/* <div className="space-y-12 border-[1px]  border-gray-900/10 ">

                    <div className=" border-gray-900/10 pb-2 text-center">
                        <h2 className="text-3xl  font-semibold leading-7 text-gray-900">Donation Laga</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                </div> */}
                    <FormHeading heading={'Donar Details / डोनर  विवरण'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">


                        {/* एन्ट्री तारीख */}
                        {/* <div className="sm:col-span-3">
                        <Input
                            label="एन्ट्री तारीख"
                            placeholder="डोनेशन तारीख"
                            type="text"
                            required="required"
                            disabled

                        />
                        {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>}
                    </div> */}

                        <div className="sm:col-span-3">
                            <div className="sm:col-span-3">
                                <Input
                                    label="Samaj Member Code / समाज का मेंबर कोड"
                                    placeholder="Samaj Member Code"
                                    type="text"
                                    {...register("samajMemberCode", {
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "कृपया 20 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("samajMemberCode", e)}
                                />
                                {errors.samajMemberCode && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.samajMemberCode.message}</p>}
                            </div>
                        </div>

                        {/* सदस्य का नाम */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Donar Name / सदस्य का नाम"
                                placeholder="Donar Name"
                                type="text"
                                required="required"

                                {...register("donarName", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: "कृपया 20 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("donarName", e)}
                            />
                            {errors.donarName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.donarName.message}</p>}
                        </div>

                        {/* सदस्य का मोबाइल नंबर */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Mobile No /मोबाइल नंब"
                                placeholder="Mobile No"
                                type="text"
                                required="required"
                                {...register("donarMobileNumber", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अंक भरें"
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "कृपया 10 अंक से अधिक न भरें"
                                    },
                                    pattern: {
                                        value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                        message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("donarMobileNumber", e)}
                            />
                            {errors.donarMobileNumber && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.donarMobileNumber.message}</p>}
                        </div>

                        {/* gotra */}
                        <div className="sm:col-span-3">
                            <SelectInput
                                label="Gotra / गोत्र"
                                placeholder="Gotra"
                                options={gotra}
                                required="required"
                                onChange={(e) => handleChange("gotra", e)}
                            />
                            {/* {errors.gotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gotra.message}</p>} */}
                        </div>

                        {/* सदस्य का  शहर */}
                        {/* सदस्य का  शहर */}
                        <div className="sm:col-span-3">
                            <Input
                                label="City / सदस्य का शहर"
                                placeholder="City"
                                type="text"
                                required="required"
                                {...register("donarCity", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: "कृपया 20 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("donarCity", e)}
                            />
                            {errors.donarCity && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.donarCity.message}</p>}
                        </div>


                        {/* सदस्य का  पता */}
                        <div className="col-span-full">
                            <Textarea
                                label="Address / सदस्य का पता"
                                placeholder="Address"
                                type="text"
                                required="required"

                                {...register("donarAddress", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 20,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "कृपया 20 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("donarAddress", e)}
                            />
                            {errors.donarAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.donarAddress.message}</p>}

                        </div>
                    </div>

                    <FormHeading heading={'Donation Details / डोनेशन राशि विवरण  '} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* डोनेशन की राशि */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Donation Amount / डोनेशन की राशि"
                                placeholder="Donation Amount"
                                type="number"
                                required="required"
                                min="1000"
                                {...register("donationAmount", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    min: {
                                        value: 100,
                                        message: "कृपया 100 या अधिक राशि दर्ज करें"
                                    },
                                    // maxLength: {
                                    //     value: 10,
                                    //     message: "कृपया 10 अंकों से अधिक न भरें"
                                    // }
                                })}
                                onChange={(e) => handleChange("donationAmount", e)}
                            />
                            {errors.donationAmount && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.donationAmount.message}</p>}
                        </div>
                        {/* प्राप्ति दिनांक */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Recieve Date / प्राप्ति दिनांक"
                                placeholder="Recieve Date"
                                required="required"
                                type="date"
                                {...register("donationReciveDate", { required: "इस फ़ील्ड को भरना ज़रूरी है" })}
                                onChange={(e) => handleChange("donationReciveDate", e)}
                            />
                            {errors.donationReciveDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationReciveDate.message}</p>}
                        </div>

                        {/* Donation Recived By */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Donation Receivers MemberShip Code / डोनेशन पाने वाले का कोड"
                                placeholder="Donation Receivers MemberShip Code"
                                type="text"
                                required="required"

                                {...register("donationReciverMembershipCode", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: "कृपया 20 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("donationReciverMembershipCode", e)}
                            />
                            {errors.donationReciverMembershipCode && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.donationReciverMembershipCode.message}</p>}
                        </div>
                    </div>

                    <FormHeading heading={'Donation Category / डोनेशन केटेगरी '} />
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-1 gap-x-6 gap-y-4 mx-2 md:mx-5">
                        <fieldset className='flex'>
                            <div className="flex flex-col md:flex-row flex-wrap w-full gap-4 justify-evenly">
                                <div className="relative flex gap-x-3">
                                    <div className="flex ">
                                        <CheackBox
                                            label="Samaj laga / समाज का मेंबर लागा"
                                            placeholder="Samaj laga"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            onChange={(e) => handleChange("donationategory", e)}
                                            {...register("samajMemberLaga",)}
                                        />
                                    </div>
                                </div>
                                <div className="relative flex gap-x-3">
                                    <div className="flex ">
                                        <CheackBox
                                            label="Help fund / सहयोग राशि"
                                            placeholder="Help fund"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"

                                            onChange={(e) => handleChange("donationategory", e)}
                                            {...register("contributionAmount",)}
                                        />
                                    </div>
                                </div>
                                <div className="relative flex gap-x-3">
                                    <div className="flex ">
                                        <CheackBox
                                            label="Self decided / सहमति राशि"
                                            placeholder="Self decided"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"


                                            onChange={(e) => handleChange("donationategory", e)}
                                            {...register("agreedAmount",)}
                                        />

                                    </div>
                                </div>
                                <div className="relative flex gap-x-3">
                                    <div className="flex ">
                                        <CheackBox
                                            label="Donation / डोनेशन"
                                            placeholder="Donation"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"

                                            onChange={(e) => handleChange("donationategory", e)}
                                            {...register("donation",)}
                                        />

                                    </div>
                                </div>
                                <div className="relative flex gap-x-3">
                                    <div className="flex ">
                                        <CheackBox
                                            label="Other / अन्य"
                                            placeholder="Other"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"

                                            onChange={(e) => handleChange("donationategory", e)}
                                            {...register("other",)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <FormHeading heading={'Photos'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* Donar Photo */}
                        <div className="sm:col-span-3 w-full">
                            <Input
                                label="Donar Photo / दानी व्यक्ति फोटो"
                                placeholder="Donar Photo"
                                type="file"
                                required="required"
                                onChange={(e) => handleFileChange("donarPhoto", e)}
                            />
                            {errors.donarPhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.donarPhoto.message}</p>}
                        </div>
                        {/* Event Photo 1 */}
                        <div className="sm:col-span-3 w-full">
                            <Input
                                label="Event Photo 1 / कार्यक्रम फोटो 1"
                                placeholder="Event Photo 1"
                                type="file"
                                onChange={(e) => handleFileChange("eventPhoto1", e)}
                            />
                            {errors.eventPhoto1 && <p id="eventPhoto1-error" className="text-red-500 text-xs mt-1">{errors.eventPhoto1.message}</p>}
                        </div>

                        {/* Event Photo 2 */}
                        <div className="sm:col-span-3 w-full">
                            <Input
                                label="Event Photo 2 / कार्यक्रम फोटो 2"
                                placeholder="Event Photo 2"
                                type="file"
                                onChange={(e) => handleFileChange("eventPhoto2", e)}
                            />
                            {errors.eventPhoto2 && <p id="eventPhoto2-error" className="text-red-500 text-xs mt-1">{errors.eventPhoto2.message}</p>}
                        </div>

                    </div>

                    <div className="col-span-full">
                        <Textarea
                            label="Remark / रिमार्क"
                            placeholder="Remark"
                            type="text"
                            {...register("remark", {
                                // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                // value: `${formData?.permanentAddress}`,
                                minLength: {
                                    value: 10,
                                    message: "कृपया कम से कम 20 अक्षर भरें"
                                },
                                maxLength: {
                                    value: 1000,
                                    message: "कृपया 1000 अक्षरों से कम भरें"
                                }
                            })}
                            onChange={(e) => handleChange("remark", e)}
                        />
                        {errors.remark && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.remark.message}</p>}

                    </div>

                    <div className='flex flex-wrap justify-around mt-5'>

                        {/* <PhotoUpload
                        label="Donar Photo"
                        accept="image/*"
                        type="file"
                        name="donarPhoto"
                        required=""
                        className="sr-only"
                        register={register}
                        setValue={setValue}
                    /> */}

                    </div>
                    {/* </div> */}
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

{

    {/* 1)if checke on cheque open feild for cheacj number and cheque date
    2) same for upi upi id and upi dateTime
    3) banktran id & datetime */}

    {/* confirmationByUserId */ }
    {/* confirmationDateTime */ }
    {/* 4} deppsit to to account number and name    */ }
}

// {/* प्राप्ति का तरीका */}
// {/* <div className="sm:col-span-3 ">
// <label className="block required text-sm font-extrabold leading-6 text-gray-900">
//     प्राप्ति का तरीका
// </label>

// <div className='w-full flex  items-start justify-start flex-col'>
//     <div className="">
//         <CheackBox
//             label="बैंक ट्रांसफर"
//             placeholder="डोनेशन तारीख"
//             name="bankTransfer"
//             type="checkbox"
//             value="banktransfer"
//             {...register("bankTransfer")}
//             onChange={(e) => setIsBankTransferChecked(e.target.checked)}
//         />
//         {/* {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>} */}
//     </div>
//     {/* cheque number */}

//     {isBankTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//         <div className="sm:col-span-3 w-[100%] m">
//             <Input
//                 label="Bank Transfer number"
//                 placeholder="Bank Transfer number"
//                 type="number"
//                 required="required"
//                 {...register("bankTransferChequeNum", {
//                     required: "इस फ़ील्ड को भरना ज़रूरी है",
//                     minLength: {
//                         value: 15,
//                         message: "कृपया कम से कम 15 अंक भरें"
//                     },
//                     maxLength: {
//                         value: 15,
//                         message: "कृपया 15 अंक से अधिक न भरें"
//                     },
//                     pattern: {
//                         value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                         message: "कृपया मान्य  नंबर दर्ज करें"
//                     }
//                 })}
//                 onChange={(e) => handleChange("bankTransferChequeNum", e)}
//             />
//             {errors.bankTransferChequeNum && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bankTransferChequeNum.message}</p>}
//         </div>
//         {/* cheque date */}
//         <div className="sm:col-span-3 w-[100%] ">
//             <Input
//                 label="Transfer Date"
//                 placeholder="Transfer Date"
//                 type="date"
//                 required="required"
//                 {...register("bankTransferDate", {
//                     required: "इस फ़ील्ड को भरना ज़रूरी है",
//                     // minLength: {
//                     //     value: 15,
//                     //     message: "कृपया कम से कम 15 अंक भरें"
//                     // },
//                     // maxLength: {
//                     //     value: 15,
//                     //     message: "कृपया 15 अंक से अधिक न भरें"
//                     // },
//                     // pattern: {
//                     //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                     //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                     // }
//                 })}
//                 onChange={(e) => handleChange("bankTransferDate", e)}
//             />
//             {errors.bankTransferDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bankTransferDate.message}</p>}
//         </div>
//     </div>}

//     {/* कॅश */}
//     <div className="">
//         <CheackBox
//             label=" कॅश"
//             placeholder=" कॅश"
//             type="checkbox"
//             name="reciveMethod"
//             value="cash"
//             onChange={(e) => handleChange("reciveMethod", e)}
//             {...register("reciveMethod",)}
//         />
//         {/* {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>} */}
//     </div>

//     {/* UPI */}
//     <div className="">
//         <CheackBox
//             label="यु पि आए"
//             placeholder="यु पि आए"
//             type="checkbox"
//             name="upiTranfer"

//             {...register("upiTranfer",)}
//             onChange={(e) => setIsUpiTransferChecked(e.target.checked)}

//         />
//         {/* {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>} */}
//     </div>
//     {isUpiTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//         <div className="sm:col-span-3 w-[100%] m">
//             <Input
//                 label="UPI Transaction ID"
//                 placeholder="UPI Transaction ID"
//                 type="number"
//                 required="required"
//                 {...register("upiTransactionID", {
//                     required: "इस फ़ील्ड को भरना ज़रूरी है",
//                     minLength: {
//                         value: 15,
//                         message: "कृपया कम से कम 15 अंक भरें"
//                     },
//                     maxLength: {
//                         value: 15,
//                         message: "कृपया 15 अंक से अधिक न भरें"
//                     },
//                     pattern: {
//                         value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                         message: "कृपया मान्य  नंबर दर्ज करें"
//                     }
//                 })}
//                 onChange={(e) => handleChange("upiTransactionID", e)}
//             />
//             {errors.upiTransactionID && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.upiTransactionID.message}</p>}
//         </div>
//         {/* cheque date */}
//         <div className="sm:col-span-3 w-[100%] ">
//             <Input
//                 label="UPI Transaction Date"
//                 placeholder="UPI Transaction Date"
//                 type="date"
//                 required="required"
//                 {...register("upiTransactionDate", {
//                     required: "इस फ़ील्ड को भरना ज़रूरी है",
//                     // minLength: {
//                     //     value: 15,
//                     //     message: "कृपया कम से कम 15 अंक भरें"
//                     // },
//                     // maxLength: {
//                     //     value: 15,
//                     //     message: "कृपया 15 अंक से अधिक न भरें"
//                     // },
//                     // pattern: {
//                     //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                     //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                     // }
//                 })}
//                 onChange={(e) => handleChange("upiTransactionDate", e)}
//             />
//             {errors.upiTransactionDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.upiTransactionDate.message}</p>}
//         </div>
//     </div>}
//     {/* bycheque 10Digit */}
//     <div className="">
//         <CheackBox
//             label=" चेक "
//             placeholder=" चेक "
//             type="checkbox"
//             name="reciveMethod"
//             {...register("chequeTansger",)}
//             onChange={(e) => setIsChequeTransferChecked(e.target.checked)}

//         />
//         {/* {errors.donationDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.donationDate.message}</p>} */}
//     </div>
//     {isChequeTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//         <div className="sm:col-span-3 w-[100%] m">
//             <Input
//                 label="Cheque Transaction ID"
//                 placeholder="Cheque Transaction ID"
//                 type="number"
//                 required="required"
//                 {...register("chequeTransactionID", {
//                     required: "इस फ़ील्ड को भरना ज़रूरी है",
//                     minLength: {
//                         value: 15,
//                         message: "कृपया कम से कम 15 अंक भरें"
//                     },
//                     maxLength: {
//                         value: 15,
//                         message: "कृपया 15 अंक से अधिक न भरें"
//                     },
//                     pattern: {
//                         value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                         message: "कृपया मान्य  नंबर दर्ज करें"
//                     }
//                 })}
//                 onChange={(e) => handleChange("chequeTransactionID", e)}
//             />
//             {errors.chequeTransactionID && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.chequeTransactionID.message}</p>}
//         </div>
//         {/* cheque date */}
//         <div className="sm:col-span-3 w-[100%] ">
//             <Input
//                 label="cheque Transaction Date"
//                 placeholder="cheque Transaction Date"
//                 type="date"
//                 required="required"
//                 {...register("chequeTransactionDate", {
//                     required: "इस फ़ील्ड को भरना ज़रूरी है",
//                     // minLength: {
//                     //     value: 15,
//                     //     message: "कृपया कम से कम 15 अंक भरें"
//                     // },
//                     // maxLength: {
//                     //     value: 15,
//                     //     message: "कृपया 15 अंक से अधिक न भरें"
//                     // },
//                     // pattern: {
//                     //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                     //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                     // }
//                 })}
//                 onChange={(e) => handleChange("chequeTransactionDate", e)}
//             />
//             {errors.chequeTransactionDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.chequeTransactionDate.message}</p>}
//         </div>
//     </div>}
// </div>
// </div> */}