import React, { useEffect, useRef, useState } from 'react'


export default function SearchCriteria({ options, search, id, setOptions, label, className, style }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        let filteredData = []
        if (search == 'city') {
            filteredData = options?.filter(item =>
                item.name?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
        } else {
            filteredData = options?.filter(item =>
                item?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
        }
        setFilteredData(filteredData);
    }, [searchTerm, options]);


    const handleInputChange = e => {
        const { value } = e.target;
        setSearchTerm(e.target.value);
        if (search == 'city' && value) {
            const filteredData = options?.filter(item => item['name']?.toLowerCase().includes(value?.toLowerCase()));
            if (filteredData.length > 0) {
                setOptions(prev => ({ ...prev, [search]: { 'name': value, 'id': filteredData[0].id } }));
            }
        } else {
            setOptions(prev => ({ ...prev, [search]: value }));
        }
    };


    const handleInputFocus = () => {

        console.log('handleInputFocus')
        setIsFocused(true);
    };


    const clearSearch = () => {
        setIsFocused(false);
        setSearchTerm("");
        setOptions(prev => ({ ...prev, [search]: "" }));
    };


    const selectHandlerValue = (item) => {
        console.log('item', item)
        setOptions(pre => ({ ...pre, [search]: item }))
        if ([search] == 'city') {
            setSearchTerm(item['name']);
        } else {
            setSearchTerm(item);
        }

        setIsFocused(false);
    }

    const handleFocusOut = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 500)
    }




    return (
        <div className={`relative  `}>
            <label htmlFor={label} className={`block capitalize text-sm font-extrabold leading-6 text-gray-900 ${style}`}>
                {label}
            </label>
            <input
                type="text"
                id={label}
                name={label}
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleFocusOut}
                placeholder="Search..."
                autoComplete="off"
                className={` ${className} `}
            />
            <div className="absolute inset-y-0   right-0 flex items-center pr-2 mt-5">
                {isFocused ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={clearSearch}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                )}
            </div>
            {
                isFocused && (
                    <div className={`absolute w-[90%] md:w-[100%]  h-52 overflow-y-auto z-10 top-full bg-white border border-gray-300 rounded-b-md shadow-lg`} >
                        {filteredData?.map((item, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            >
                                <p onClick={() => selectHandlerValue(item)}> {search == 'city' ? item['name'] : item}</p>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    )
}
