import React, { useEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { HeaderBoarder } from '../Components'
import { useForm } from 'react-hook-form';
import { Input, SelectInput, Textarea } from '../FormsInputes.js';
import Swal from 'sweetalert2';
import { addSuggession, generateLaga } from '../service/Services.js';
import AdminButton from './AdminButtons.js';
import LoadingModal from './LoadingModel.js';


// entryIdAauto generateP
// membercode paidby null,
// Amount min 100,
// payment Date same datefor mayment null,
// laga year not null,
// recEMemberCode session null,
// lagaSatus notPaid,


export default function CreatlagaForm() {

    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({

    });
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const submit = async (data) => {
        console.log(data)
        try {
            setLoading(true);
            console.log(data)

            // Call addNews API
            const isLagaAdded = await generateLaga(data);

            // Check if news added successfully
            if (isLagaAdded?.success) {
                Swal.fire({
                    title: isLagaAdded.success,
                    text: isLagaAdded.message,
                    icon: 'success',
                });
                reset()



            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    // addSuggession
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };
    return (
        <>
            <LoadingModal show={isLoading} text={'Creating...'} />
            <HeaderBoarder heading={"Add Laga"} />
            <div className='w-100  md:px-10 '>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'Creat Laga'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        <div className="sm:col-span-3">
                            <Input
                                id="year-input"
                                name="year"
                                label="Year"
                                placeholder="year"
                                type="number"
                                min="1900"
                                max="2099"

                                required="required"
                                title="Please enter a valid year between 1900 and 2099"
                                {...register("lagaYear", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",


                                })}
                                onChange={(e) => handleChange("lagaYear", e)}
                            />
                            {errors.lagaYear && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.lagaYear.message}</p>}

                        </div>

                        {/* <div className="sm:col-span-3">
                            <Input
                                id="amount-input"
                                name="lagaAmount"
                                label="Amount"
                                placeholder="Amount"
                                type="number"
                                min="100"

                                required="required"
                                title="Please enter a more than 100"
                                {...register("lagaAmount", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                                onChange={(e) => handleChange("lagaAmount", e)}
                            />
                            {errors.lagaAmount && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.lagaAmount.message}</p>}
                        </div> */}




                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Creat
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>

                </form>
            </div>
        </>
    )
}
// suggesstions entry id-