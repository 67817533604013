import logo from './logo.svg';
import './App.css';
import Route from "./Pages/Route"
import { Provider, useDispatch } from "react-redux"
import store from './store/store';
import { createContext, useEffect, useLayoutEffect, useState } from 'react';
import ScrollToTop from './Layouts/ScrollToTOp';


const LoginContext = createContext()
function App() {
  // const { isAuthenticated,setIsAuthenticated,  clearData } = useSessionStorage('myData');
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = ''; // This line may not work on modern Hashs due to security restrictions
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = function (event) {
  //   window.history.go(1);
  // };



  return (
    // <LoginContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
    <Provider store={store}>
      <div className="App ">
        <Route />
      </div>
      <ScrollToTop />
    </Provider>
    // </LoginContext.Provider>
  );
}

export default App;

export {
  LoginContext
}
