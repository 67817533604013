import React from 'react'
import { Link } from 'react-router-dom';
import { HeaderBoarder } from '../../Components';

export default function AdminHomePage() {


    // const [isOpen, setOpen] = useState(true)
    // useEffect(() => {
    //     const hasModalBeenShown = sessionStorage.getItem('hasModalBeenShown');

    //     if (hasModalBeenShown) {
    //         setOpen(false); // Set isOpen to false if the modal has been shown before
    //     }
    //     //  else {
    //     // }
    // }, []);

    // const images = [
    //     { filename: "mandirdarshan", file: mandirdarshan, url: "/mandirdarshan" },
    //     { filename: "committee", file: committee, url: "/committee" },
    //     { filename: "members", file: members, url: "/members" },
    //     { filename: "rishtey", file: rishtey, url: "/rishtey" },
    //     { filename: "bookdharmashala", file: bookdharmashala, url: "/bookdharmashala" },
    //     { filename: "programs", file: programs, url: "/programs" },
    //     { filename: "bloodbank", file: bloodbank, url: "/bloodbank" },
    //     { filename: "donations", file: donations, url: "/donations" },
    //     { filename: "achievements", file: achievements, url: "/achievements" },
    //     { filename: "samajcalender", file: samajcalender, url: "/samajcalender" },
    //     { filename: "digitalcard", file: digitalcard, url: "/digitalcard" },
    //     { filename: "promotion", file: promotion, url: "/promotion" },
    //     { filename: "complaints", file: complaints, url: "/suzhav" },
    //     { filename: "invitations", file: invitations, url: "/invitations" },
    //     { filename: "shoksamachar", file: shoksamachar, url: "/shoksamachar" },
    //     { filename: "jobs", file: jobs, url: "/jobs" },
    //     { filename: "careerGuidance", file: careerGuidance, url: "/careerGuidance" },
    //     { filename: "businessprofile", file: businessprofile, url: "/businessprofile" },
    //     { filename: "registration", file: registration, url: "/registration" },
    //     { filename: "artical", file: artical, url: "/artical" },
    // ];


    return (
        <>

            <HeaderBoarder />
            {/* <Header /> */}
            {/* <HeaderBoarder />
            <ShowAds isOpen={isOpen} toggleModal={setOpen} /> */}
            <div className='flex flex-wrap  items-start  px-5  '>
                <div className='flex flex-wrap  items-start sm:px-5 mb-5  '>
                    {/* {images.map((image, index) => (
                        <Link to={'/dashboard' + image.url} key={index}>
                            <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" />
                        </Link>
                    ))} */}
                    <Link className=' btn rounded-lg text-white  bg-blue-800 p-5 font-bold text-center' to={'/admin/UserManagement'} >
                        UserManagement
                        {/* <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" /> */}
                    </Link>

                    <Link className=' btn rounded-lg text-white  bg-blue-800 p-5 font-bold text-center' to={'/admin/LagaManagement'} >
                        LagaManagement
                        {/* <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" /> */}
                    </Link>

                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
}
