import React, { useState, useEffect, useRef } from "react";

const TextToSpeech = ({ text = "" }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [chunks, setChunks] = useState([]);
    const [currentChunkText, setCurrentChunkText] = useState('');

    // Ref for onStart function
    const onStartRef = useRef();

    // Function to handle speech start
    onStartRef.current = () => {
        setIsSpeaking(true);
    };

    // Function to split text into smaller chunks
    const splitTextIntoChunks = (text) => {
        const chunkSize = 150; // Adjust based on speech performance
        const chunks = [];
        for (let i = 0; i < text?.length; i += chunkSize) {
            chunks.push(text?.substring(i, i + chunkSize));
        }
        return chunks;
    };

    useEffect(() => {
        const synth = window.speechSynthesis;
        // Set the chunks
        setChunks(splitTextIntoChunks(text));

        // Cleanup function
        return () => {
            synth.cancel();
        };
    }, [text]);
    // Function to speak a chunk
    const speakChunk = (chunk, index) => {
        const utterance = new SpeechSynthesisUtterance(chunk);
        utterance.lang = 'hi-IN'; // Set language for Hindi
        utterance.voiceURI = 'Google हिन्दी'; // Set voice for Hindi
        utterance.onstart = onStartRef.current; // Set onstart event listener
        utterance.onend = () => {
            // Check if there are more chunks to speak
            if (index < chunks.length - 1) {
                speakChunk(chunks[index + 1], index + 1); // Speak the next chunk recursively
            } else {
                setIsSpeaking(false); // If no more chunks, set speaking to false
            }
        };
        window.speechSynthesis.speak(utterance);
    };

    // Function to speak the first chunk
    const speakFirstChunk = () => {
        if (chunks.length > 0) {
            setIsSpeaking(true); // Set speaking to true
            setCurrentChunkText(chunks[0]); // Set current chunk text
            speakChunk(chunks[0], 0); // Speak the first chunk
        }
    };

    return (
        <div className="w-full text-end">
            {isSpeaking ? (
                <span className="cursor-pointer" onClick={() => window.speechSynthesis.cancel()}>
                    &#128263;
                </span>
            ) : (
                <span className="cursor-pointer" onClick={speakFirstChunk}>
                    &#128266;
                </span>
            )}
        </div>
    );
};

export default TextToSpeech;
