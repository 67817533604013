import React from 'react'
import { Footer } from '../../Components'
import Header from './header'
import { RegistrationForm } from '../../Forms'

export default function HomeRegistationForm() {
    return (<>

        <RegistrationForm />

    </>
    )
}
