import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';


const usePostDataApi = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = async (url, formData, options) => {
        setLoading(true);
        try {
            const response = await axios.post(url, formData, options, {
                headers: {
                    // Make sure to set the content type
                    ...options
                },
            });
            console.log("response", response)
            setData(response.data);
        } catch (error) {
            setData()
            // alert("error", error)
            console.error(error.response.data.message)
            Swal.fire({
                title: error?.response?.data?.message,
                text: 'Custom message goes here!',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, postData };
};

export default usePostDataApi;
