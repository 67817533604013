import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { setUser } from "../Redux/authSlicer";
import { Footer, Header } from "../Components";


const PrivateRoute = () => {
    const isAuthenticated = useSelector(state => state?.auth?.isLogin);
    if (!isAuthenticated) return <Navigate to="/" />;
    return <> <Header /> <Outlet /> <Footer /> </>;
};

export default PrivateRoute;