import React, { useState } from 'react'

import callporwal from "../assets/dashbordBtn/callporwal.png"
import chatporwal from "../assets/dashbordBtn/chatporwal.png"
import messageporwal from "../assets/dashbordBtn/messageporwal.png"
import viewporwal from "../assets/dashbordBtn/viewporwal.png"
import vishalprofile from "../assets/membersimegas/vishalprofile.jpg"
import QR from "../assets/bloodCard/QR.png"
import blooddrop from "../assets/bloodCard/blooddrop.png"
import call from "../assets/final cards for mayur/female matromony/femalematricallbutton.png"
import chat from "../assets/final cards for mayur/female matromony/femalematrichatbutton.png"
import viwe from "../assets/final cards for mayur/female matromony/femalematriviewprofile.png"

import { PopupModel } from '.'
import { Domain } from '../ApiConfig'


export default function UserCard({ member, ref }) {

    // const [isOpen, setIsOpen] = useState(false);
    // // console.log(style)

    // console.log(member)

    // const togglePopup = () => {
    //     setIsOpen(!isOpen);
    // };
    // console.log("member", member)
    return (
        <>
            {/* <PopupModel isOpen={isOpen} setIsOpen={setIsOpen} popupData={member} /> */}

            <div ref={ref} className='h-[200px] m-2 w-[350px] border-r-5 border  rounded-xl shadow-2xl bg-white'>
                <div className='blood-card-header w-full flex p-3  items-center h-[60px] bg-fuchsia-400 border-r-5 border  rounded-xl'>
                    <div className=' w-[50px] flex justify-start '>
                        <img className="h-[2.8rem] w-[2.8rem] rounded-full flex-none bg-blue-400" src={`${Domain + member?.profilePhoto?.replace(" ", "%20")}`} alt="" loading="lazy" />
                    </div>
                    <h1 className=' text-yellow-400 font-bold w-full text-center'>{member.name}</h1>
                </div>

                <div className='flex w-full justify-between h-[145px] p-3 h-100'>
                    <div className='flex h-100   items-end     '>
                        <img className='w-10 h-10' src={QR} loading='lazy' />
                    </div>

                    <div className=' font-semibold tracking-tighter tex-[12px]'>
                        <p className=' text-gray-700 font-mono'>Membercode : {member.membershipCode}</p>
                        <div className=' flex flex-col justify-center items-center w-full text-center'>
                            <p className='blood-card-header px-5  rounded-xl    text-3xl font-extrabold text-yellow-500'>{member.bloodGroup}</p>
                            <p className=' text-gray-700 font-mono'>{member.city.name} {member.State.name}</p>

                            <div className=' metrimony-card-bg-bottom ring-gray-400 ring-2 m flex rounded-3xl p-1 px-5   gap-2 justify-end  h-100'>
                                <img className='h-7 w-7 ' src={call} />
                                <img className='h-7 w-7 ' src={chat} />
                                <img className='h-7 w-7 ' src={viwe} />
                            </div>

                        </div>


                    </div>
                    <div>
                        <img className=' w-[4.5rem] h-[100%]' src={blooddrop} loading='lazy' />
                    </div>
                </div>

            </div>

        </>


    )
}

{/* <div className='h-100 m-2 min-w-[330px]     border-r-5 border  rounded-xl shadow-2xl bg-white text-black  '>
                <div className='membercardHeadin  bg-[#172553] rounded-t-xl mt-[-0.5px] px-4 py-1 text-center text-white font-bold '>
                    {'MEM0001'}
                </div>
                <div className="p-2 flex justify-center flex-col ">
                    <div className="  ">
                        <div className="flex min-w-0 gap-x-4 items-center ">
                            <div className='border-2 p-[0.5px] border-orange-500 rounded-full  mb-2'>
                                <img className="h-[3.5rem] w-[3.5rem] rounded-full flex-none bg-blue-400" src={`${Domain + member.profilePhoto.replace(" ", "%20")}`} alt="" loading="lazy" />
                            </div>
                            <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                <p className="text-[15px] flex font-bold   tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between' >नाम <span>: </span> </span> {member.name} </p>
                                <p className="text-[15px] flex  font-bold tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between'>शहर  <span>: </span> </span> {member.city.name} </p>
                                <p className="text-[13px] flex font-bold  text-gray-600 " ><span className=' w-[50px] me-2 flex justify-between font-bold'>ब्लड ग्रुप <span>: </span>  </span> <span className=' text-red-600 font-extrabold'>{member.bloodGroup} </span></p>

                            </div>

                        </div>
                        <div className='flex justify-center bg-gray-200 items-end gap-3 mt-4'>
                            <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-8' src={callporwal} alt="Call" loading="lazy" /> <span className='text-[12px] leading-7 font-bold tracking-tight text-gray-900'>Call</span></p>
                            <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-8' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'>Chat</span></p>
                            <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-8' src={messageporwal} alt="Message" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> Message</span></p>
                        </div>
                       
                    </div>

                </div>
            </div> */}
