import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'; // Use session storage
import authSlicer, { requestOTP, verifyOTP } from "../Redux/authSlicer";
import memberSlicer from "../Redux/memberSlicer";
import { encryptData, decryptData } from './encryptionUtils'; // Import encryption utilities
import paginationSlicer from "../Redux/paginationSlicer";


// Define the persistence configuration
const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['auth'], // Reducers you want to persist
    transforms: [
        {
            // Transform to encrypt the entire state before storing
            in: (state) => encryptData(state),
            // Transform to decrypt the entire state after retrieval
            out: (state) => decryptData(state)
        }
    ]
};

// Wrap the root reducer with the persistence configuration
const persistedReducer = persistReducer(persistConfig, combineReducers({
    member: memberSlicer,
    auth: authSlicer,
    pagination: paginationSlicer

}));
// Ignore specific action types for serialization check
// const ignoreSerializableCheckMiddleware = getDefaultMiddleware({
//     serializableCheck: {
//         ignoredActions: ['persist/PURGE'],
//     },
// });
// Create the Redux store
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({

            serializableCheck: false,
        }),
});

// Export the Redux store and persistor for rehydration
export const persistor = persistStore(store);
export default store;
// Function to handle logout
export const handleLogout = () => {
    // Perform any additional logout logic here
    // Purge/delete the persisted storage
    persistor.purge();

    // Redirect or perform any other action after logout
    // For example, redirect to the login page
    // history.push('/');
};
