
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    profilePhoto: "",
    membershipCode: 'Not Actived',
    bloodGroup: '',
    name: '',
    email: '',
    surname: '',
    mobileNo: '',
    whatsappNo: '',
    fatherName: '',
    motherName: '',
    gotra: '',
    birthDate: '',
    permanentAddress: '',
    currentAddress: '',
    city: '',
    country: '',
    State: '',
    pincode: '',
    currentAddressArea: '',
    occupation: '',
    educationQualification: '',
    memberFamilyDetails: [{
        memberName: '',
        age: '',
        dob: '',
        gender: 'Male',
        relation: '',
        maritalStatus: ''
    }]
}
const memberSlice = createSlice({
    name: "member",
    initialState,
    reducers: {

        getmember(state) {
            return state
        },

        setMember(state, action) {
            console.log("action.payload member", action.payload)
            state = action.payload; // Update state directly
        },

    }
})

export const { getMember, setMember } = memberSlice.actions;
export default memberSlice.reducer;