import React, { useState } from 'react'
import FormHeading from './FormHeading'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Input, Textarea } from '../FormsInputes.js';
import { HeaderBoarder } from '../Components/index.js';
import { addAchivement } from '../service/Services.js';

export default function Achivement() {
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();
    const [samajShow, setSamajShow] = useState([]);


    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };

    const handleFileChange = (fimeName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fimeName, file);
    };
    // const submit = (data) => {
    //     console.log(data)
    // }

    const samaj = [
        { text: 'porwal samaj indor', id: 1 },
        { text: 'porwal samaj Bhopal', id: 2 },
        { text: 'porwal samaj Shendhwa', id: 3 },
        { text: 'porwal samaj Balwani', id: 4 }
    ];

    const onSamajSelectChange = (e) => {
        const { value } = e.target;
        const { text } = JSON.parse(value)

        setSamajShow((prevSamajShow) => prevSamajShow?.text?.includes(text) ? prevSamajShow : [...prevSamajShow, JSON.parse(value)]);
    };
    const handleRemoveSamajShow = (indexToRemove) => {
        setSamajShow((prevSamajShow) => prevSamajShow.filter((_, index) => index !== indexToRemove));
    };

    const submit = async (data) => {
        try {
            const requiredFields = ['achievementHeading', 'achiverName', 'message', 'profilePhoto'];
            const hasMissingFields = requiredFields.some(field => !data[field]);
            if (hasMissingFields) {
                throw new Error("All Star fields are required");
            }
            setLoading(true);
            console.log(data)
            // Create FormData object
            const formData = new FormData();
            const entryInSamaj = {
                curentSamaj: "Indor porwal samaj",
                id: 1
            }
            formData.append('achievementHeading', data.achievementHeading);
            formData.append('samajToShow', JSON.stringify(samajShow));
            formData.append('entryInSamaj', JSON.stringify(entryInSamaj));
            formData.append('achiverName', data.achiverName);
            formData.append('message', data.message);
            formData.append('profilePhoto', data.profilePhoto);
            formData.append('photo1', data?.photo1);
            formData.append('photo2', data?.photo2);


            // Call addNews API
            const isNewsAdded = await addAchivement(formData);

            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
            setSamajShow([])
        }
    };

    return (
        <>
            <HeaderBoarder heading={"उपलब्धियां"} />
            <div className='w-100  md:px-10 '>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'उपलब्धियां'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* entry member code -- */}
                        {/* <div className="sm:col-span-3">
                            <Input
                                label="entry member code"
                                placeholder="entry member code"
                                type="text"
                                required="required"

                                {...register("entryMemberCode", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                            />
                            {errors.entryMemberCode && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.entryMemberCode.message}</p>}
                        </div> */}
                        {/* member name */}
                        {/* <div className="sm:col-span-3">
                            <Input
                                label="Member Name"
                                placeholder="Member Name"
                                type="text"
                                required="required"

                                {...register("memberName", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                            />
                            {errors.memberName && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.memberName.message}</p>}
                        </div> */}

                        {/* achievement heading */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Achievement heading / उपलब्धि शीर्षक"
                                placeholder="Achievement heading"
                                type="text"
                                required="required"

                                {...register("achievementHeading", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: "कृपया 200 अक्षरों से कम भरें"
                                    }

                                })}
                            />
                            {errors.achievementHeading && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.achievementHeading.message}</p>}
                        </div>

                        {/* achiver name */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Achiver name / नाम"
                                placeholder="achiver name"
                                type="text"
                                required="required"

                                {...register("achiverName", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                            />
                            {errors.achiverName && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.achiverName.message}</p>}
                        </div>

                        {/* place/location */}

                        {/* <div className="sm:col-span-3">
                            <Input
                                label="place/location"
                                placeholder="place/location"
                                type="text"
                                required="required"

                                {...register("placeLocation", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                            />
                            {errors.placeLocation && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.placeLocation.message}</p>}
                        </div> */}

                        {/* profile Photo--2 */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Achiver photo / फोटो"
                                placeholder="achiver photo"
                                type="file"
                                required="required"
                                onChange={(e) => handleFileChange("profilePhoto", e)}
                            />
                            {errors.profilePhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.profilePhoto.message}</p>}
                        </div>

                        {/* photo--2 */}
                        <div className="sm:col-span-3">
                            <Input
                                label="photo 1 / फोटो 1"
                                placeholder="photo 1"
                                type="file"

                                onChange={(e) => handleFileChange("photo1", e)}
                            />
                            {errors.photo1 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.photo1.message}</p>}
                        </div>
                        <div className="sm:col-span-3">
                            <Input
                                label="photo 2 / फोटो 2"
                                placeholder="photo 2"
                                type="file"

                                onChange={(e) => handleFileChange("photo2", e)}
                            />
                            {errors.photo2 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.photo2.message}</p>}
                        </div>


                        <div className={` ${samajShow.length > 0 ? 'flex' : 'hidden'} px-2 col-span-full h-100 flex-wrap flex gap-2 rounded-md border-0 py-1.5 p-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}>
                            {samajShow.map((item, index) => (
                                <div className='text-center rounded-md bg-blue-500 w-max px-4 font-semibold text-white' key={index}>
                                    {item.text}
                                    <span className='ml-5 cursor-pointer' onClick={() => handleRemoveSamajShow(index)}>X</span>
                                </div>
                            ))}
                        </div>

                        <div className='sm:col-span-3 mx-2  '>
                            <label className=' font-extrabold'>Samaj To Show / जिस समाज में बताना है</label>
                            {/* <div className='sm:col-span-3 ps-2'> */}
                            <select onChange={onSamajSelectChange} className='block ${className} px-2 w-full md:w-[48%] mt-2  rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>

                                {samaj.map(option => (
                                    <option key={option.id} value={JSON.stringify(option)} >
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Details---compulsary------1200 character */}

                        <div className="col-span-full mx-2 ">
                            <Textarea
                                label="message / संदेश"
                                placeholder="message"
                                type="text"
                                {...register("message", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    // value: `${formData?.permanentAddress}`,
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 20 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 2000,
                                        message: "कृपया 2000 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("message", e)}
                            />
                            {errors.message && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.message.message}</p>}

                        </div>

                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>

                </form>
            </div>
        </>
    )
}
