import React, { useEffect, useState } from 'react'
import { searchMemberForMemberByAdmimForActivation, updateMemberStatus } from '../../service/Services';
import { SearchCriteria } from '../../FormsInputes.js';
import { gotra } from "../../constants/staticData.js"
import axios from 'axios';
import { showErrorAlert, showSuccessAlert } from '../../Components/ShowAlertCustome.js';
import LoadingModal from '../../Forms/LoadingModel.js';

export default function UserManagment() {

    const [criteria, setCriteria] = useState({ name: "", surname: "", userType: "", gotra: "", stustus: "", membershipID: "", mobileNo: "" })
    const [currentPage, setCurrentPage] = useState(1);


    const [totalPages, setTotalPages] = useState(1);
    const [byName, setByName] = useState([])
    const [occupation, setOccupation] = useState([])
    const [surename, setSurname] = useState([])
    const [membershipID, setmembershipID] = useState([])
    const [mobileNo, setMobileNo] = useState([])

    const [memberStatus, setMemberStatus] = useState(['Not Activated', 'Activated', 'Block'])
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [members, setMember] = useState([]);
    const totalItemInPage = 10;


    useEffect(() => {
        setLoading(true)
        console.log(currentPage)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                // console.log('criteria', criteria)
                const memberData = await searchMemberForMemberByAdmimForActivation(criteria, requestOptions)
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
                console.log('searchMemberForMemberPage', memberData);
                if (memberData?.success) {
                    const data = memberData?.data || [];
                    setTotalPages(memberData?.pagination?.totalPages || 1);
                    console.log(data) // Set total pages

                    // setTotalPages(memberData?.pagination?.totalPages);
                    // setPagination(memberData?.pagination)
                    // dispatch(setPagination(memberData?.pagination))
                    console.log(data)
                    setMember(data)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [criteria, currentPage])
    // searchMemberForMemberByAdmimForActivation
    // 1 member type, member it, name gontra, registation date, stustus ,
    useEffect(() => {
        const uniqueNames = [...new Set(members.map(member => member.name))];
        setByName(uniqueNames);
        // console.log(uniqueNames)

        const uniqueSurnames = [...new Set(members.map(member => member.surname))];
        setSurname(uniqueSurnames);
        // const uniqueGotras = [...new Set(members.map(member => member.gotra))];
        // setGotra(uniqueGotras);
        // const uniquesetmembershipID = [...new Set(members.map(member => member?.membershipID))];
        // setmembershipID(uniquesetmembershipID);

    }, [members.length])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        setMember(pre => [])
        setTotalPages(1)
        setCurrentPage(pre => 1)
    }, [criteria])


    return (
        <>
            <LoadingModal show={isLoading} text={'Updating...'} />
            <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">
                <div className="   ">
                    {/* name */}
                    <SearchCriteria
                        options={byName}
                        search={'name'}
                        id={'name'}
                        setOptions={setCriteria}
                        label={"नाम"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}

                    />
                </div>

                {/* surename */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={surename}
                        search={'surname'}
                        id={'surname'}
                        setOptions={setCriteria}
                        label={"उपनाम"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* memberStatus */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={memberStatus}
                        search={'memberStatus'}
                        id={'memberStatus'}
                        setOptions={setCriteria}
                        label={"member Status"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* gotra */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={gotra}
                        search={'gotra'}
                        id={'gotra'}
                        setOptions={setCriteria}
                        label={"गोत्र"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* membershipID */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={membershipID}
                        search={'membershipID'}
                        id={'membershipID'}
                        setOptions={setCriteria}
                        label={"membershipID"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

                {/* mobileNo */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={mobileNo}
                        search={'mobileNo'}
                        id={'mobileNo'}
                        setOptions={setCriteria}
                        label={"मोबाइल नंबर"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>
            </div>
            <div className="px-2  py-4">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="w-full bg-gray-100 text-left">
                                <th className="py-2 px-4 border-b">Sr No</th>
                                <th className="py-2 px-4 border-b">UserType</th>
                                <th className="py-2 px-4 border-b">UserName</th>
                                <th className="py-2 px-4 border-b">Contact No</th>
                                <th className="py-2 px-4 border-b">Registration DateTime</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Gotra</th>
                                <th className="py-2 px-4 border-b">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {members.length != 0 &&
                                members.map((member, index) => {
                                    return <tr className="hover:bg-gray-100" key={index}>
                                        <CreatTable member={member} index={index + 1} setLoading={setLoading} />
                                    </tr>
                                })
                            }

                            {/* Add more rows as needed */}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />        </>
    )
}

const CreatTable = ({ member, index, setLoading }) => {
    const [status, setStatus] = useState("");
    const [changeId, setChangeId] = useState('')

    const handleStatusChange = (event, membershipID) => {
        setStatus(event.target.value);
        setChangeId(membershipID)
    };

    const changeStatus = async () => {
        if (changeId === member?.membershipID)
            try {
                setLoading(true)
                const response = await updateMemberStatus({ membershipID: member?.membershipID, memberStatus: status });
                console.log("response", response)
                if (response?.success) {
                    showSuccessAlert(response?.message);
                }
            } catch (error) {
                console.error('Error while updating member status:', error);
                // Handle error if necessary
                showErrorAlert(error?.message)
            } finally {
                setLoading(false)
            }
    };

    return (
        <>
            <td className="py-2 px-4 border-b">{index}</td>
            <td className="py-2 px-4 border-b">{member?.userType}</td>
            <td className="py-2 px-4 border-b">{member?.name}</td>
            <td className="py-2 px-4 border-b">{member?.mobileNo}</td>
            <td className="py-2 px-4 border-b">{new Date(member?.creationdate).toLocaleString()}</td>
            <td className="py-2  border-b">
                <select
                    value={status}
                    onChange={(e) => handleStatusChange(e, member?.membershipID)}
                    className="border px-6 border-gray-300 rounded p-1"
                >
                    <option value={member?.memberStatus}>{member?.memberStatus}</option>
                    <option value="Activated">Activated</option>
                    <option value="Not Activated">Not Activated</option>
                    <option value="Block">Block</option>
                </select>
            </td>
            <td className="py-2 px-4 border-b">{member?.gotra}</td>
            <td className="py-2 px-4 border-b">
                <button onClick={() => changeStatus(member?.membershipID)} className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600">
                    Update
                </button>
            </td>
        </>
    );
};

// export CreatTable;





export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const maxPagesToShow = 5;
        const half = Math.floor(maxPagesToShow / 2);
        let start = Math.max(1, currentPage - half);
        let end = Math.min(totalPages, currentPage + half);

        if (currentPage <= half) {
            end = Math.min(totalPages, maxPagesToShow);
        }

        if (currentPage + half >= totalPages) {
            start = Math.max(1, totalPages - maxPagesToShow + 1);
        }

        const pages = [];
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }
        return pages;
    };

    const pageNumbers = getPageNumbers();

    return (
        <nav className="flex justify-center">
            <ul className="flex list-none">
                {currentPage > 1 && (
                    <li className="mr-2">
                        <button
                            onClick={() => onPageChange(currentPage - 1)}
                            className="px-3 py-1 border rounded"
                        >
                            Previous
                        </button>
                    </li>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className="mr-2">
                        <button
                            onClick={() => onPageChange(number)}
                            className={`px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : ''}`}
                        >
                            {number}
                        </button>
                    </li>
                ))}
                {currentPage < totalPages && (
                    <li>
                        <button
                            onClick={() => onPageChange(currentPage + 1)}
                            className="px-3 py-1 border rounded"
                        >
                            Next
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};




// export Pagination;

