import React from 'react'
import { ComingSoon } from '../../../Components'

export default function Registration() {
  return (
    <div>
      <ComingSoon />
    </div>
  )
}
