import React from 'react'
import { ComingSoon } from '../../../Components'

export default function Digitalcard() {
    return (
        <div>
            <ComingSoon />
        </div>
    )
}
