import React, { useState } from 'react'
import FormHeading from './FormHeading'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { Input, Textarea } from '../FormsInputes.js';
import { HeaderBoarder } from '../Components/index.js';
import { addsamajcalenderEvent } from '../service/Services.js';
import AdminButton from './AdminButtons.js';
const samaj = [
    { text: 'porwal samaj indor', id: 1 },
    { text: 'porwal samaj Bhopal', id: 2 },
    { text: 'porwal samaj Shendhwa', id: 3 },
    { text: 'porwal samaj Balwani', id: 4 }
];

export default function SamajCalenderForm() {
    const [isLoading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();
    const [samajShow, setSamajShow] = useState([]);
    const [isError, setError] = useState(false)
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };
    const handleFileChange = (fimeName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fimeName, file);
    };
    // addsamajcalenderEvent
    const submit = async (data) => {
        console.log(data)
        try {
            const requiredFields = ['monthYear', 'orderMemberShipCode', 'programDate', 'placeLocation', 'programHeading', 'photo1', 'photo2'];
            const hasMissingFields = requiredFields.some(field => !data[field]);
            if (hasMissingFields) {
                throw new Error("All Star fields are required");
            }
            setLoading(true);
            console.log(data)
            // Create FormData object
            const formData = new FormData();
            const entryInSamaj = {
                curentSamaj: "Indor porwal samaj",
                id: 1
            }

            formData.append('monthYear', data.monthYear);
            formData.append('samajToShow', JSON.stringify(samajShow));
            formData.append('entryInSamaj', JSON.stringify(entryInSamaj));
            formData.append('orderMemberShipCode', data.orderMemberShipCode);
            formData.append('placeLocation', data.placeLocation);
            formData.append('programDate', new Date(data.programDate));
            formData.append('programHeading', data.programHeading);
            formData.append('details', data.details);
            formData.append('photo1', data?.photo1);
            formData.append('photo2', data?.photo2);
            formData.append('eventPhotosLink', data.eventPhotosLink);
            formData.append('eventVideosLink', data.eventVideosLink);





            const isNewsAdded = await addsamajcalenderEvent(formData);

            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()



            }
        } catch (error) {
            console.error(error);
            setError(error);
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
            });
        } finally {
            setLoading(false);
            setSamajShow([])
        }
    };

    const onSamajSelectChange = (e) => {
        const { value } = e.target;
        const { text } = JSON.parse(value)

        setSamajShow((prevSamajShow) => prevSamajShow?.text?.includes(text) ? prevSamajShow : [...prevSamajShow, JSON.parse(value)]);
    };
    const handleRemoveSamajShow = (indexToRemove) => {
        setSamajShow((prevSamajShow) => prevSamajShow.filter((_, index) => index !== indexToRemove));
    };

    return (
        <>
            <HeaderBoarder heading={"समाज कैलेंडर"} />
            <div className='w-100  md:px-10 '>
                <AdminButton text="कार्यक्रम जोड़ें" className=" bg-blue-950 text-white" url="/admin/calender" />

                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'Samaj Calender / समाज कैलेंडर'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* month/year---compulsary */}
                        <div className="sm:col-span-3">
                            <Input
                                label="month/year / महीना - साल"
                                placeholder="month/year"
                                type="month"
                                required="required"

                                {...register("monthYear", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                            />
                            {errors.monthYear && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.monthYear.message}</p>}
                        </div>
                        {/* Program date */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Program date / कार्यक्रम तारीख"
                                placeholder="Program date"
                                type="date"
                                required="required"

                                {...register("programDate", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",

                                })}
                            />
                            {errors.programDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.programDate.message}</p>}
                        </div>

                        {/* Program heading */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Program heading / कार्यक्रम शीर्षक"
                                placeholder="Program heading"
                                type="text"
                                required="required"

                                {...register("programHeading", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: "कृपया 40 अक्षरों से कम भरें"
                                    }
                                })}
                            />
                            {errors.programHeading && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.programHeading.message}</p>}
                        </div>

                        {/* place/location */}

                        <div className="sm:col-span-3">
                            <Input
                                label="place/location / जगह - लोकेशन पता"
                                placeholder="place/location"
                                type="text"
                                required="required"

                                {...register("placeLocation", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: "कृपया 40 अक्षरों से कम भरें"
                                    }
                                })}
                            />
                            {errors.placeLocation && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.placeLocation.message}</p>}
                        </div>

                        <div className="sm:col-span-3">
                            <Input
                                label="MemberShip Code / कार्यक्रम आर्डर मेंबर का कोड"
                                placeholder="MemberShip Code"
                                type="text"
                                required="required"

                                {...register("orderMemberShipCode", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 8,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 8,
                                        message: "कृपया 8 अक्षरों से कम भरें"
                                    }
                                })}
                            />
                            {errors.orderMemberShipCode && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.orderMemberShipCode.message}</p>}
                        </div>

                        {/* photo--2 */}
                        <div className="sm:col-span-3">
                            <Input
                                label="photo 1 / फोटो 1"
                                placeholder="photo 1"
                                type="file"
                                required="required"
                                onChange={(e) => handleFileChange("photo1", e)}
                            />
                            {errors.photo1 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.photo1.message}</p>}
                        </div>
                        <div className="sm:col-span-3">
                            <Input
                                label="photo 2 / फोटो 2"
                                placeholder="photo 2"
                                type="file"
                                required="required"
                                onChange={(e) => handleFileChange("photo2", e)}
                            />
                            {errors.photo2 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.photo2.message}</p>}
                        </div>


                        <div className={` ${samajShow.length > 0 ? 'flex' : 'hidden'} px-2 col-span-full h-100 flex-wrap flex gap-2 rounded-md border-0 py-1.5 p-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}>
                            {samajShow.map((item, index) => (
                                <div className='text-center rounded-md bg-blue-500 w-max px-4 font-semibold text-white' key={index}>
                                    {item.text}
                                    <span className='ml-5 cursor-pointer' onClick={() => handleRemoveSamajShow(index)}>X</span>
                                </div>
                            ))}
                        </div>

                        <div className='sm:col-span-3 mx-2  '>
                            <label className=' font-extrabold'>Samaj To show / समाज मे दिखाना</label>
                            {/* <div className='sm:col-span-3 ps-2'> */}
                            <select onChange={onSamajSelectChange} className='block ${className} px-2 w-full md:w-[48%] mt-2  rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>

                                {samaj.map(option => (
                                    <option key={option.id} value={JSON.stringify(option)} >
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* event photos link */}
                        <div className="sm:col-span-3">
                            <Input
                                label="event photos link / कार्यक्रम फोटो लिंक"
                                placeholder="event photos link"
                                type="text"
                                {...register("eventPhotosLink", {

                                    pattern: {
                                        value: /^(https?:\/\/)?drive\.google\.com\/.*$/,
                                        message: "कृपया एक Google Drive लिंक दर्ज करें"
                                    },
                                })}
                            />
                            {errors.eventPhotosLink && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.eventPhotosLink.message}</p>}
                        </div>
                        {/* event videos link */}
                        <div className="sm:col-span-3">
                            <Input
                                label="event videos link / कार्यक्रम वीडियो लिंक"
                                placeholder="event videos link"
                                type="text"
                                {...register("eventVideosLink", {

                                    pattern: {
                                        value: /^(https?:\/\/)?drive\.google\.com\/.*$/,
                                        message: "कृपया एक Google Drive लिंक दर्ज करें"
                                    },

                                })}
                            />
                            {errors.eventVideosLink && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.eventVideosLink.message}</p>}
                        </div>




                        {/* Details---compulsary------1200 character */}
                        <div className="col-span-full mx-2 ">
                            <Textarea
                                label="Details / कार्यक्रम जानकारी "
                                placeholder="Details"
                                type="text"
                                {...register("details", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    // value: `${formData?.permanentAddress}`,
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 20 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 2000,
                                        message: "कृपया 2000 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("details", e)}
                            />
                            {errors.details && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.details.message}</p>}

                        </div>

                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>

                </form>
            </div>
        </>
    )
}
