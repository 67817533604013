import React from 'react';

const LoadingModal = ({ show, text }) => {
    return (
        <div className={`fixed inset-0 z-50 overflow-hidden ${show ? '' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                <div className=" ">
                    <div className="flex items-center justify-center">
                        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-800 rounded-full animate-spin"></div>
                    </div>
                    <div className="mt-4 text-center">
                        <p className="text-lg font-semibold">{text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingModal;
