import React, { useEffect, useState } from 'react'
import { Domain } from '../ApiConfig'

export default function SamajCalenderCard({ event }) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    // console.log(event)
    return (
        <>
            <ViwePopup isOpen={isOpen} setIsOpen={setIsOpen} event={event} />

            <div className='h-100 m-2 min-w-[330px]     border-r-5 border   shadow-2xl bg-white text-black  ' >
                <div className={`membercardHeadin bg-[#1E3A8A]    mt-[-0.5px] px-4 py-1 text-center text-white font-bold `}>
                    {event.programHeading}
                </div>
                <div className="p-2 flex justify-center flex-col ">
                    <div className="  ">
                        <div className="flex min-w-0 gap-x-4 items-center ">
                            {/* <div className='border-2 p-[0.5px] mt-3 border-orange-500 rounded-full  '> */}
                            <img className="h-[3.5rem] ring-2 ring-orange-400 w-[3.5rem]  flex-none rounded-full bg-blue-400" src={`${Domain + event.photo1.replace(" ", "%20")}`} alt="" loading="lazy" />
                            {/* </div> */}
                            <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                {/* <p className="text-[17px] ms-6 font-extrabold  " >  </p> */}
                                <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[50px] me-2 flex justify-between'>तिथि  <span>: </span> </span>
                                    {new Date(event?.programDate).toDateString()}
                                </p>




                                <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[60px] me-2 flex justify-between'>स्थान <span >:</span></span>
                                    {event.placeLocation}
                                </p>
                                {/* <p className="text-[13px] flex  font-bold tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between'> व्यवसाय <span>: </span> </span> बिजनेस </p> */}
                            </div>
                        </div>
                        {/* <div className='flex justify-center bg-gray-200 items-end gap-3 mt-4'>
                <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-8' src={callporwal} alt="Call" loading="lazy" /> <span className='text-[12px] leading-7 font-bold tracking-tight text-gray-900'>Call</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-8' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'>Chat</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-8' src={messageporwal} alt="Message" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> Message</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={togglePopup}> <img className='h-8' src={viewporwal} alt="View" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> View</span></p>
            </div> */}
                        {/* Online indicator */}
                        {/* <div className="h-3 w-3 bg-green-400 rounded-full"></div> */}
                        <div className=' w-full flex justify-center'>
                            <button onClick={togglePopup} className=' px-4 mt-2 rounded-lg cursor-pointer h-100 bg-orange-600 text-white '> view Details </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

const ViwePopup = ({ isOpen, setIsOpen, event, }) => {
    // console.log(popupData)
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Add event listener when component mounts
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
        }

        // Remove event listener when component unmounts
        return () => {
            document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className="fixed top-0 left-0 flex items-center px-2 justify-center w-full h-full bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded shadow-2xl w-full  md:w-96  overflow-y-auto">
                        <div className='  min-w-[330px]     border-r-5 border   shadow-2xl bg-white text-black  ' >
                            <div className={`membercardHeadin bg-[#1E3A8A]     text-center text-white font-bold `}>
                                {event.programHeading}
                            </div>
                            <div className="p-2 flex justify-center flex-col ">
                                <div className=" flex gap-3  ">
                                    <img className=" w-14 h-14 ring-2 ring-orange-400    flex-none rounded-full " src={`${Domain + event.photo1.replace(" ", "%20")}`} alt="" loading="lazy" />

                                    <div className="flex min-w-0 gap-x-4 items-center ">
                                        {/* <div className='border-2 p-[0.5px] mt-3 border-orange-500 rounded-full  '> */}
                                        {/* </div> */}
                                        <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                            {/* <p className="text-[17px] ms-6 font-extrabold  " >  </p> */}
                                            <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[65px] me-2 flex justify-between'>तिथि  <span className=' ms-2'>: </span> </span>
                                                {new Date(event?.programDate).toDateString()}
                                            </p>


                                            <p className="text-[15px]  flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[130px] me-2 flex justify-between'>जानकारी <span className=' ms-2' >:</span></span>
                                                {event.details}
                                            </p>

                                            <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[75px] me-2 flex justify-between'>स्थान <span className=' ms-2' >:</span></span>
                                                {event.placeLocation}
                                            </p>
                                            {/* <p className="text-[13px] flex  font-bold tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between'> व्यवसाय <span>: </span> </span> बिजनेस </p> */}
                                        </div>
                                    </div>
                                    {/* <div className='flex justify-center bg-gray-200 items-end gap-3 mt-4'>
                <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-8' src={callporwal} alt="Call" loading="lazy" /> <span className='text-[12px] leading-7 font-bold tracking-tight text-gray-900'>Call</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-8' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'>Chat</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-8' src={messageporwal} alt="Message" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> Message</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={togglePopup}> <img className='h-8' src={viewporwal} alt="View" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> View</span></p>
            </div> */}
                                    {/* Online indicator */}
                                    {/* <div className="h-3 w-3 bg-green-400 rounded-full"></div> */}

                                </div>

                            </div>
                        </div>

                        <div className="w-full flex justify-center mt-auto"> {/* mt-auto will push the button to the bottom */}
                            <button
                                className="mt-4 bg-[#172553] hover:bg-blue-700 text-white font-bold py-2 px-10 rounded-full"
                                onClick={togglePopup}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

            )}

        </>
    );
};
