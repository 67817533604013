import React from 'react'
import ComingSoonImg from "../assets/comingSoon.png"

export default function ComingSoon() {
    return (
        <div className=' w-screen h-full  flex justify-center items-center '>
            <img className='  ' src={ComingSoonImg} loading='lazy' />
        </div>
    )
}
