import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { request_otp, verify_otp, update_member } from '../ApiConfig';
import { storeAccessToken } from '../service/sessionStor';
import { upDateMember } from '../service/Services';

// Async thunks for login, request OTP, and verify OTP (replace with your API endpoints)
export const requestOTP = createAsyncThunk(
    'auth/requestOTP',
    async ({ mobileNumber }) => {
        const header = {
            'Content-Type': 'application/json'
        };

        const body = {
            phoneNumber: mobileNumber
        };

        const requestOptions = {

            headers: header,
            withCredentials: true
        };

        try {
            const response = await axios.post(request_otp, body, requestOptions);
            // const data = await response.json();

            console.log(response)

            if (!response.data.success) {
                throw new Error('Failed to send OTP');
            }

            return response.data;
        } catch (error) {
            console.log(error.response.data.message)
            throw new Error(error.response.data.message);
        }
    }
);

export const verifyOTP = createAsyncThunk(
    'auth/verifyOTP',
    async ({ otpStr, mobileNumber }) => {

        const header = {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${sessionToken}`, // Replace with your token variable name
        };

        const body = {
            otp: otpStr,
            registered_mobile_number: mobileNumber
        }
        const requestOptions = {

            headers: header,
            withCredentials: true, // Include credentials if necessary
        };

        try {
            console.log("'Content-Type'", otpStr, mobileNumber);
            const response = await axios.post(verify_otp, body, requestOptions);
            // const data = await response.json();
            console.log("response", response);
            if (!response.data.success) { // Assuming success property in response
                // console.log("response.data.success", data);
                throw new Error('Failed to verify OTP');
            }
            // console.log("isOtpVerified", data); // Log the entire response data
            // setSessionToken(data?.sessionToken); // Update sessionToken if present in response
            return response.data;
            // Assuming success property indicates success
        } catch (error) {
            console.error("Failed to verify OTP:", error);
            throw new Error(error.response?.data?.message); // Re-throw for handling in calling code
        }
    }
);

// const onEditHandler = async (e) => {
//     e.preventDefault();
//     try {
//         // Create form data
//         const uploadForm = handleFormData(formData);
//         uploadForm.append('memberFamilyDetails', JSON.stringify(formData.memberFamilyDetails));
//         // console.log("formData", formData)
//         // uploadForm.uppend()
//         // Set up headers


//         // Make the request
//         const res = await axios.put(update_member, uploadForm, {
//             headers: headers,
//             withCredentials: true // Send cookies with the request
//         });

//         // Handle response
//         console.log(res.data);
//     } catch (error) {
//         // Handle error
//         console.error('Error updating member:', error);
//     }
// }
export const updateMember = createAsyncThunk(
    'auth/updateMember',
    async (uploadForm, userType) => {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const requestOptions = {
            headers: headers,
            withCredentials: true, // Include credentials if necessary
        };
        try {
            // console.log("'Content-Type'", otpStr, mobileNumber);
            // const response = await axios.put(update_member, uploadForm, requestOptions);
            const response = await upDateMember(uploadForm, userType, requestOptions)
            // const data = await response.json();
            console.log("response", response);
            if (!response.success) { // Assuming success property in response
                // console.log("response.data.success", data);
                throw new Error('Failed to update');
            }
            // console.log("isOtpVerified", data); // Log the entire response data
            // setSessionToken(data?.sessionToken); // Update sessionToken if present in response
            return response;
            // Assuming success property indicates success
        } catch (error) {
            console.error("Failed to update:", error);
            throw new Error(error.response?.message); // Re-throw for handling in calling code
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: null,
        isLogin: null,
        otp: null,
        loading: false,
        error: null,
    },
    reducers: {
        setError(state, action) {
            state.error = action.payload;
        },
        resetError(state) {
            state.error = null;
        },
        setOTP(state, action) {
            state.otp = action.payload;
        },
        logOutUser(state, action) {

            return {
                user: null,
                token: null,
                isLogin: null,
                otp: null,
                loading: false,
                error: null,
            }
        },
        setUser(state, action) {
            const { refreshToken, ...userData } = action.payload.data;
            console.log("action.payload", action.payload)
            storeAccessToken(refreshToken);
            return {
                ...state,
                loading: false,
                user: userData,
                token: refreshToken,
                isLogin: true,
                otp: null
            };

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestOTP.pending, (state) => {
                state.isLogin = false;
                state.loading = true;
            })
            .addCase(requestOTP.fulfilled, (state, action) => {
                state.loading = false;
                state.isLogin = false;
                state.otp = action.payload;
            })
            .addCase(requestOTP.rejected, (state, action) => {
                state.loading = false;
                state.isLogin = false;
                state.error = action.error.message;
            })
            .addCase(verifyOTP.pending, (state) => {
                state.isLogin = false;
                state.loading = true;
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                const { refreshToken, ...userData } = action.payload.data;
                console.log(action.payload.data)
                console.log(refreshToken)
                console.log(userData)
                storeAccessToken(refreshToken);
                return {
                    ...state,
                    loading: false,
                    user: userData,
                    token: refreshToken,
                    isLogin: true,
                    otp: null
                };
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.loading = false;
                state.isLogin = false;
                state.error = action.error.message;
            })
            .addCase(updateMember.pending, (state) => {

                state.loading = true;
            })
            .addCase(updateMember.fulfilled, (state, action) => {
                // console.log("action.payload.data", action.payload.data)
                state.loading = false;
                state.user = action.payload.data;
                state.token = action.payload;
                state.isLogin = true;
                state.otp = null;

            })
            .addCase(updateMember.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },

});

export const { setError, resetError, setOTP, setUser, logOutUser } = authSlice.actions;
export default authSlice.reducer;
