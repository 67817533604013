import React, { useEffect, useState } from 'react'
import { getPagination } from '../service/sessionStor'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function HeaderBorder({ heading }) {

    const { totalResults } = useSelector(state => state?.pagination.pagination)
    const { section } = useParams();

    const renderPaginationDataOnPages = (section) => {
        const pageUrl = ['members', 'bloodbank',]
        if (pageUrl.includes(section)) {
            return `- ${totalResults}`
        }
        return ""
    }
    return (
        <div className={`primaryColor  w-100 h-10 flex justify-center items-center text-whit`}>
            {heading && <><span className=' text-xl sm:text-2xl text-white font-extrabold'>{heading} <span className='w-[100px]'>{renderPaginationDataOnPages(section)}</span> </span> </>}
        </div>
    )
}


