import React, { useEffect, useState, useRef, useCallback } from 'react'
import { BloodBankForm } from '../../../Forms'
import UserData from "../../../fakeData/UserData"
    ;
import { BloodBankCard } from "../../../Components/index"
import SearchDropDown from '../../../Forms.js/Criterial';
import { get_citi_by_name, get_city, getMemberByBloodAndCity, get_member, searchMemberForMemberPage } from '../../../ApiConfig';
import axios from 'axios';

import useGetApi from '../../../CostomHooks/useGetData';
import { SearchCriteria } from '../../../FormsInputes.js';
import { getMember } from '../../../service/Services.js';
import { setPagination } from '../../../Redux/paginationSlicer.js';
import useIntersectionObserver from '../../../Helper/useIntersectionObserver.js';
import { useDispatch, useSelector } from 'react-redux';
import ImageCarousel from '../../../Components/carousel.js';
const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

export default function Bloodbank() {
    const [bloodtype, setBloodGroup] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [paginationData, setPagination] = useState({})
    const [totalPages, setTotalPages] = useState(0);
    const [criteria, setCriteria] = useState({ bloodGroup: "", cityName: '', cityId: '', })
    const [city, setCity] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);
    const [members, setMember] = useState([]);
    const dispatch = useDispatch();
    const totalItemInPage = 10;
    const paginationData = useSelector(state => state?.pagination?.pagination)
    const lastItemRef = useIntersectionObserver({ currentPage, paginationData, setCurrentPage });

    useEffect(() => {
        setLoading(true)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                const { bloodGroup, ...cityData } = criteria;
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { bloodGroup, city: cityData?.city?.id }, requestOptions);
                const memberData = await getMember({ bloodGroup, city: cityData?.city?.id }, requestOptions)
                // console.log('searchMemberForMemberPage', memberData.data);
                if (memberData?.success) {
                    const data = memberData?.data || [];
                    setTotalPages(memberData?.pagination?.totalPages);
                    // setPagination(memberData?.pagination)
                    dispatch(setPagination(memberData?.pagination))
                    // console.log(memberData.data.pagination.totalPages)
                    setMember(pre => [...pre, ...data])

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [currentPage, criteria])

    useEffect(() => {
        setMember(pre => [])
        setCurrentPage(1)
    }, [criteria])

    useEffect(() => {

        // const uniqueBloodTypes = [...new Set(members.map(member => member.bloodGroup))];
        // setBloodGroup(uniqueBloodTypes);
        const uniqueCities = Array.from(new Set(members.map(member => member.city.id)))
            .map(cityId => members.find(member => member.city.id === cityId).city);
        setCity(uniqueCities);
    }, [members.length])

    // const lastItemRef = useCallback(node => {
    //     if (observer.current) observer.current.disconnect();
    //     // console.log(currentPage)
    //     observer.current = new IntersectionObserver(entries => {
    //         if (entries[0].isIntersecting && currentPage < totalPages && paginationData.hasNextPage) {
    //             console.log(currentPage)
    //             setCurrentPage(prevPage => prevPage + 1);
    //         }
    //     });
    //     if (node) observer.current.observe(node);
    // }, [totalPages]);

    const handlePageChange = (page) => {
        // setCurrentPage(page);
    };

    // if (isLoading) {
    //     return (
    //         <div className='absolute inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50'>
    //             <div className="bg-white p-4 rounded shadow">
    //                 <h1>Loading...</h1>
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className='flex h-100  flex-wrap flex-col xl:flex-row gap-2 justify-center'>
            <div className='flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5'>
                <div className="  ">
                    {/* name */}
                    <SearchCriteria
                        options={bloodGroups}
                        search={'bloodGroup'}
                        id={'bloodGroup'}
                        setOptions={setCriteria}
                        label={"ब्लड ग्रुप"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}
                    />
                </div>
                {/* city */}
                <div className="  ">
                    <SearchCriteria
                        options={city}
                        search={'city'}
                        id={'city'}
                        setOptions={setCriteria}
                        label={"शहर"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}
                    />
                </div>
            </div>
            {/* <div className="flex flex-col items-center justify-center   bg-gray-100">
                <ImageCarousel />
            </div> */}

            <div className="flex w-full flex-wrap justify-center">
                {members?.map((member, index) => (
                    (members.length === index + 1) ? <div key={index} className='thi si last index' ref={lastItemRef} ><BloodBankCard member={member} /></div> : <div key={index}  > <BloodBankCard key={index} member={member} /> </div>
                ))}

            </div>
            {isLoading && <div className="flex items-center justify-center">
                <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
            </div>}
            {!paginationData.hasNextPage && <h1 className=' text-center'>End</h1>}
        </div>
    );
}

{/* <div className="flex flex-col md:flex-row gap-5 justify-center items-center p-3 bg-gray-400 my-3">
                <div className="w-full md:w-1/4 ">
                    <label htmlFor="bloodGroup" className="block text-sm font-medium leading-6 text-gray-900">
                        Blood Group
                    </label>
                    <div className="mt-1">
                        <select
                            value={bloodtype}
                            onChange={onBloodGroupChange}
                            id="bloodGroup"
                            name="bloodGroup"
                            required
                            className="block w-full px-2 rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option>Search by Blood Groups</option>
                            {bloodGroups.map((blood, index) => (
                                <option value={encodeURIComponent(blood)} key={blood}>{blood}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="w-100 w-full md:w-1/4">
                    <div className="relative">
                        <label htmlFor="city" className="block capitalize text-sm font-medium leading-6 text-gray-900">
                            City
                        </label>
                        <input
                            type="text"
                            name="city"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onFocus={handleInputFocus}
                            placeholder="Search city "
                            autoComplete="off"
                            className="block w-full h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pt-6 pr-3">
                            {isFocused ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => setIsFocused(false)}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                            )}
                        </div>
                        {isFocused && (
                            <div className="absolute h-52 overflow-y-auto z-10 top-full w-full bg-white border border-gray-300 rounded-b-md shadow-lg">
                                {filteredData.map((item, index) => (
                                    <div key={index} className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                                        <p onClick={() => onCitiSelectHandler(item)}>{item.cityName}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div> */}