import React, { useState, useId, forwardRef, useEffect } from 'react';
import { Domain } from '../ApiConfig';

const FileUpload = forwardRef(({ label, type, accept, name, value = "", className = "", register, required, setValue, ...props }, ref) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const id = useId();
    useEffect(() => {
        // console.log(name)
        register(name); // Register the field with react-hook-form
    }, [name, register]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        // console.log(file)
        setValue(name, file);
        setSelectedFile(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
    };

    return (
        <div className="w-full  px-5  ">
            {label && <label htmlFor="cover-photo" className={`block ${required} capitalize text-sm font-extrabold  leading-6 text-gray-900`}>
                {label}
            </label>}
            <div className="mt-2  flex justify-center rounded-lg h-100 border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                    <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
                        {/* File upload form */}
                        <div className="flex items-center mr-4">
                            <label
                                htmlFor={id}
                                className="relative cursor-pointer rounded-md  bg-white font-extrabold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                                <span>Upload a file</span>
                                <input
                                    id={id}
                                    name={name}
                                    type={type}
                                    accept={accept}
                                    ref={ref}

                                    className="sr-only "
                                    {...props}
                                    onChange={handleFileChange}
                                />
                            </label>


                            <p className="pl-1">or drag and drop</p>
                        </div>

                        <img className=' w-[150px] h-[150px]' src={selectedFile ? URL.createObjectURL(selectedFile) : Domain + value} />
                    </div>
                    <p className="text-xs leading-5 text-gray-600 filename">PNG, JPG, GIF up to 5MB</p>
                </div>
            </div>
        </div>
    );
});

export default FileUpload;
