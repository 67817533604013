import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'; // Import Link for routing

export default function AdminButton({ text, className, url, ...props }) {
    const isAuthenticated = useSelector(state => state?.auth)

    if (isAuthenticated?.isLogin && isAuthenticated?.user?.userType === 1 && url.includes('/admin')) {
        return (
            <div className="w-full flex justify-end mt-3">
                <Link className={`${className} cursor-pointer px-3 text-[16px] py-1 rounded-3xl me-4`} to={url} {...props}>
                    {text}
                </Link>
            </div>
        );
    }
    return null;

    // console.log('path', path)

    // return (
    //     <div className=' w-full flex justify-end mt-3'>
    //         <Link className={`${className} cursor-pointer px-3 text-[16px]  py-1 rounded-3xl me-4`} to={url} {...props}>
    //             {text}
    //         </Link>
    //     </div>
    // );
}

