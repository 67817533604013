import React, { useEffect, useState } from 'react'
import { Domain } from '../ApiConfig'

export default function NewsCard({ news }) {
    console.log(news)
    const [isOpen, setIsOpen] = useState(false);
    // console.log(style)

    console.log(news?.city?.name)



    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    // entryDatetime
    return (
        <>
            <ViwePopup isOpen={isOpen} setIsOpen={setIsOpen} popupData={news} />
            <div className='h-100 m-2 min-w-[330px]     border-r-5 border   shadow-2xl bg-white text-black  ' >
                <div className={`membercardHeadin ${news.event == 'शोक समाचार' ? 'bg-black' : 'bg-[#00AFEF]'}   mt-[-0.5px] px-4 py-1 text-center text-white font-bold `}>
                    {news.event}
                </div>
                <div className="p-2 flex justify-center flex-col ">
                    <div className="  ">
                        <div className="flex min-w-0 gap-x-4 items-center ">
                            <div className='border-2 p-[0.5px] mt-3 border-orange-500 rounded-full  '>
                                <img className="h-[3.5rem] w-[3.5rem]  flex-none rounded-full bg-blue-400" src={`${Domain + news.shokProfile.replace(" ", "%20")}`} alt="" loading="lazy" />

                            </div>
                            <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                <p className="text-[17px] ms-6 font-extrabold  " > {news?.personName} </p>

                                <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[70px] me-2 flex justify-between'>शहर  <span>: </span> </span> {news?.city?.name} </p>

                                <p className="text-[15px] flex font-bold tracking-tight text-gray-600">
                                    <span className=' w-[70px] me-2 flex justify-between'>डेट टाइम <span >:</span></span>
                                    {new Date(news.entryDatetime).toLocaleString()}
                                </p>
                                {/* <p className="text-[13px] flex  font-bold tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between'> व्यवसाय <span>: </span> </span> बिजनेस </p> */}
                            </div>
                        </div>
                        {/* <div className='flex justify-center bg-gray-200 items-end gap-3 mt-4'>
                <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-8' src={callporwal} alt="Call" loading="lazy" /> <span className='text-[12px] leading-7 font-bold tracking-tight text-gray-900'>Call</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-8' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'>Chat</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-8' src={messageporwal} alt="Message" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> Message</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={togglePopup}> <img className='h-8' src={viewporwal} alt="View" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> View</span></p>
            </div> */}
                        {/* Online indicator */}
                        {/* <div className="h-3 w-3 bg-green-400 rounded-full"></div> */}
                        <div className=' w-full flex justify-center'>
                            <button onClick={() => setIsOpen(true)} className=' px-4 mt-2 rounded-lg cursor-pointer h-100 bg-orange-600 text-white '> view Details </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

const ViwePopup = ({ isOpen, setIsOpen, popupData, }) => {
    console.log(popupData)
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Add event listener when component mounts
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
        }

        // Remove event listener when component unmounts
        return () => {
            document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className="fixed top-0 z-[999999] left-0 flex items-center px-2 justify-center w-screen h-[100%] bg-black bg-opacity-50 ">
                    <div className="bg-white rounded shadow-2xl w-full py-20 md:w-96 h-full overflow-y-auto">
                        <div className={`relative h-16 flex justify-center items-center ${popupData.event == 'शोक समाचार' ? 'bg-black' : 'bg-[#00AFEF]'}`}>
                            <h1 className="text-xl md:text-2xl font-bold text-white">{popupData.event}</h1>
                            <img className="h-20 w-20 absolute left-3 rounded-full top-5 border-4 bg-gray-50" src={`${Domain + popupData.shokProfile.replace(" ", "%20")}`} alt="shok img" loading="lazy" />
                        </div>
                        <div className="flex flex-col    text-center text-gey-300 ms-28  text-gray-700">
                            <p className="text-[15px] flex font-bold tracking-tight"><span className="w-[70px] me-2 flex justify-between">नाम<span>: </span></span>{popupData.personName}</p>
                            <p className="text-[15px] flex font-bold tracking-tight"><span className="w-[70px] me-2 flex justify-between">डेट टाइम<span>: </span></span>{new Date(popupData.entryDatetime).toLocaleString()}</p>
                            <p className="text-[15px] flex font-bold tracking-tight"><span className="w-[70px] me-2 flex justify-between">शहर<span>: </span></span>{popupData?.city?.name}</p>
                        </div>
                        <div className="w-full min-h-40 h-100 bg-[#E6E7E8]">
                            <h1 className="w-full bg-[#606062] font-semibold text-3xl text-white text-center">समाचार</h1>
                            <div className="p-4 text-xl overflow-auto" style={{ maxHeight: 'calc(100% - 10rem)' }}>
                                {popupData.news}
                            </div>
                        </div>
                        <div className="w-full flex justify-center mt-auto"> {/* mt-auto will push the button to the bottom */}
                            <button
                                className="mt-4 bg-[#172553] hover:bg-blue-700 text-white font-bold py-2 px-10 rounded-full"
                                onClick={togglePopup}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

            )}

        </>
    );
};
