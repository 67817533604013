import React, { useEffect, useState } from 'react';
import FormHeading from './FormHeading';
import { PopupModel } from '../Components';
import { usePostDataApi } from '../CostomHooks';

// import axios from 'axios';

const CommitteeForm = () => {
    const [formData, setFormData] = useState({
        profilePhoto: "",
        committeeName: '',
        committeePost: '',
        committeeMobileNo: '',
        committeeWhatsappNo: '',
        committeeDetails: '',
    });


    const { loading, error, postData } = usePostDataApi();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData)
            // Call your API endpoint with formData
            const uploadForm = new FormData();
            for (let key in formData) {
                if (typeof formData[key] === 'object' && formData[key] !== null) {

                    for (let nestedKey in formData[key]) {
                        uploadForm.append(`${key}.${nestedKey}`, formData[key][nestedKey]);
                    }
                } else {
                    uploadForm.append(key, formData[key]);
                }
            }
            console.log("form data", uploadForm);
            postData('http://localhost:8000/api/v1/addCommittee', uploadForm)
        } catch (error) {
            console.error('Registration failed', error);
            // Handle error, show error message, etc.
        }
    };

    const onClear = () => {
        setFormData({
            profilePhoto: "",
            committeeName: '',
            committeePost: '',
            committeeMobileNo: '',
            committeeWhatsappNo: '',
            committeeDetails: '',
        })
    }

    if (loading) {
        return (
            <div className='absolute inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50'>
                <div className="bg-white p-4 rounded shadow">
                    <h1>Loading...</h1>
                </div>
            </div>
        );
    }

    if (error) {
        console.log(error)
    }



    return (
        <div className='w-100  md:px-40 '>

            <form className='border-[1px] border-gray-900/10 p-4'>
                <div className="space-y-12 border-[1px] border-gray-900/10 ">
                    <div className=" border-gray-900/10 pb-2">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Committee Details</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>

                    <div className=" border-gray-900/10 pb-2">
                        <FormHeading heading={'committeedetails'} />
                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                            <div className="sm:col-span-3">
                                <label htmlFor="committeename" className="block required text-sm font-medium leading-6 text-gray-900">
                                    Committee Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={formData.committeeName} onChange={handleChange}
                                        type="text"
                                        name="committeeName"
                                        id="committeename"
                                        placeholder='Committee Name'
                                        autoComplete="given-name"
                                        className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="committeepost" className="block required text-sm font-medium leading-6 text-gray-900">
                                    Committee Post
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={formData.committeePost} onChange={handleChange}
                                        type="text"
                                        name="committeePost"
                                        id="committeepost"
                                        placeholder='Committee Post'
                                        autoComplete="family-name"
                                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="committeemobileno" className="block required text-sm font-medium leading-6 text-gray-900">
                                    Committee Mobile No
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={formData.committeeMobileNo} onChange={handleChange}
                                        type="number"
                                        name="committeeMobileNo"
                                        id="committeemobileno"
                                        placeholder='Committee Mobile No'
                                        autoComplete="family-name"
                                        className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="committeewhatsappno" className="block required text-sm font-medium leading-6 text-gray-900">
                                    Committee Whatsapp No
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={formData.committeeWhatsappNo} onChange={handleChange}
                                        type="number"
                                        name="committeeWhatsappNo"
                                        id="committeewhatsappno"
                                        placeholder='Committee Whatsapp No'
                                        autoComplete="family-name"
                                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-full mx-5">
                            <label htmlFor="committeeDetails" className="block  text-sm font-medium leading-6 text-gray-900">
                                Committee Details
                            </label>
                            <div className="mt-1">
                                <textarea
                                    value={formData.committeeDetails} onChange={handleChange}
                                    id="committeeDetails"
                                    name="committeeDetails"
                                    rows={3}
                                    placeholder='Committee Details'
                                    className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                            </div>
                            {/* <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p> */}
                        </div>
                    </div>


                    <FormHeading heading={'Profile Photo'} />
                    <div className="col-span-full mx-2 md:mx-5">
                        <label htmlFor="cover-photo" className="block required text-sm font-medium leading-6 text-gray-900">
                            Member Photo
                        </label>
                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div className="text-center">
                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Upload a file</span>
                                        <input
                                            // value={formData.profilePhoto}
                                            //  onChange={handleChange}

                                            id="file-upload"
                                            name="profilePhoto" // Make sure the name attribute matches the key in your form data
                                            type="file"
                                            className="sr-only"
                                            // value={formData.profilePhoto}
                                            onChange={(e) => {
                                                // Handle file upload here, e.target.files contains the selected file(s)
                                                const selectedFile = e.target.files[0];
                                                console.log(selectedFile);
                                                setFormData({ ...formData, profilePhoto: selectedFile });
                                                document.getElementsByClassName('filename')[0].innerText = selectedFile.name
                                            }}
                                        />
                                    </label>
                                    <p className="pl-1 ">or drag and drop</p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600 filename">PNG, JPG, GIF up to 5MB</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mt-6 flex items-center justify-center gap-x-6">
                    <button onClick={onClear} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        Clear
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CommitteeForm;
