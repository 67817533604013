
import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { useForm } from 'react-hook-form'
import { CheackBox, Input, InputDate, PhotoUpload, SearchDropDownCriteria, Textarea } from '../FormsInputes.js'
import axios from 'axios';
import Swal from 'sweetalert2';
import { get_city, get_country, get_state } from '../ApiConfig.js';
import { bookDharmashala } from '../service/Services.js';
import LoadingModal from './LoadingModel.js';
import Calendar from "../assets/calender.png"
import BookingCalendar from './bookingCalender.js';
import { HeaderBoarder } from '../Components/index.js';


const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const calculateDuration = (entryDateTime, exitDateTime) => {

    if (!entryDateTime, !entryDateTime) return `00 days 00 hours`;
    // Convert date-time strings to Date objects
    const entryDate = new Date(entryDateTime);
    const exitDate = new Date(exitDateTime);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(exitDate - entryDate);
    // Calculate days and hours
    const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return `${days}days ${hours} hours`;
};













const suvida = [
    { text: 'Food / खाना', suvidha: 'food' },
    { text: 'Snacks / नाश्ता', suvidha: 'breakfast' },
    { text: 'AC Rooms / ऐसी कमरे', suvidha: 'acRoom' },
    { text: 'Non AC Rooms / नॉन ऐसी कमरे', suvidha: 'nonAcRoom' },
    { text: 'Water / पानी', suvidha: 'water' },
    { text: 'Tent House / टेंट हाउस', suvidha: 'tentHouse' },
    { text: 'Chairs / कुर्सियां', suvidha: 'chairs' },
    { text: 'Sound System / साउंड सिस्टम', suvidha: 'sound' },
    // { text: 'सिस्टम', suvidha: 'system' },
    { text: 'Lighting / लाइटिंग', suvidha: 'lighting' },
    { text: 'Stage / स्टेज', suvidha: 'stage' },
    { text: 'Decoration / डेकोरेशन', suvidha: 'decoration' }
];


export default function DharmashalaForm() {
    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();
    const today = new Date();
    const [todayDate, setTodayDate] = useState(formatDate(today));
    const [selectedSuvidha, setSelectedSuvidha] = useState([]);
    const [suvidhaError, setSuvidhaError] = useState(false)
    const [isBankTransferChecked, setIsBankTransferChecked] = useState(false);
    const [isUpiTransferChecked, setIsUpiTransferChecked] = useState(false);
    const [isChequeTransferChecked, setIsChequeTransferChecked] = useState(false);

    const [state, setState] = useState([])
    const [country, setCountry] = useState([])
    const [city, setCity] = useState([])
    const [value, setValueLocation] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)




    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };


    // Handler function to toggle selected checkboxes
    const handleCheckboxChange = (suvidha, checked) => {
        if (checked) {
            setSelectedSuvidha(prevSelected => [...prevSelected, suvidha]);
            setSuvidhaError(false)

        } else {
            setSelectedSuvidha(prevSelected => prevSelected.filter(item => item !== suvidha));
        }

        if (selectedSuvidha.length !== 0) {
            setSuvidhaError(false)
        }
    };

    const submit = async (data) => {
        console.log(selectedSuvidha.length)
        if (selectedSuvidha.length == 0) {
            setSuvidhaError(true)
            return;
        }
        setSuvidhaError(false)
        data.suvidha = selectedSuvidha
        try {
            console.log(data)
            data.bookingDurationDaysAndhours = calculateDuration(getValues('checkInDateTime'), getValues('checkOutDateTime'))
            // Validate all required fields
            if (!data.City
                || !data.State
                || !data.address
                || !data.bookingDurationDaysAndhours
                || !data.checkInDateTime
                || !data.checkOutDateTime
                || !data.mobileNumber
                || !data.suvidha
                || !data.whatsappNumber
            ) {
                throw new Error('All fields are required');
            }

            setLoading(true);
            // Call addNews API
            const isNewsAdded = await bookDharmashala(data);

            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
            setSelectedSuvidha([])
        }
    }
    useLayoutEffect(() => {
        (async () => {
            // const res = await axios(`${get_country}`)
            // console.log(res?.data?.data);
            // setCountry(pre => res?.data?.data)
            const res = await axios.get(`${get_state}/${1}`);
            setState(res?.data?.data || []);
        }
        )()
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            console.log(value);

            if (value.item === 'stateName') {
                const res = await axios.get(`${get_city}/${value?.id}`);
                setCity(res?.data?.data || []);
            }
        };
        fetchData();
    }, [value]);

    return (

        <>
            {/* <HeaderBoarder heading={"धर्मशाला बुकिंग "} /> */}
            <div className='w-100  md:px-10 '>
                <LoadingModal show={isLoading} text={'Sending...'} />
                <div className=' w-full flex justify-center '>
                    <BookingCalendar isNewBookindAdded={isLoading} />
                </div>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'Booking person Details / बुकिंग व्यक्ति जानकारी'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* <div className="sm:col-span-3">
                        <Input
                            label="डोनेशन एंट्री आईड़ी"
                            placeholder="डोनेशन एंट्री"
                            disabled
                            type="text"

                            {...register("donatinEntryIde", {
                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                minLength: {
                                    value: 3,
                                    message: "कृपया कम से कम 3 अक्षर भरें"
                                },
                                maxLength: {
                                    value: 20,
                                    message: "कृपया 20 अक्षरों से कम भरें"
                                }
                            })}
                            onChange={(e) => handleChange("donatinEntryIde", e)}
                        />
                        {errors.donatinEntryIde && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.donatinEntryIde.message}</p>}
                    </div>

                    <div className="sm:col-span-3">
                        <Input
                            label="एन्ट्री तारीख"
                            placeholder="डोनेशन तारीख"
                            type="text"
                            required="required"
                            disabled
                            {...register("dharmashala", {

                            })}
                        />
                        {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>}
                    </div> */}

                        {/* "name" */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Name / नाम"
                                placeholder="Name"
                                type="text"
                                required="required"

                                {...register("name", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: "कृपया 40 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("name", e)}
                            />
                            {errors.name && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.name.message}</p>}
                        </div>

                        {/* mobilenumber */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Mobile No / मोबाइल नंब"
                                placeholder="Mobile No"
                                type="number"
                                required="required"
                                minLength="10"
                                maxLength="10"
                                {...register("mobileNumber", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अंक भरें"
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "कृपया 10 अंक से अधिक न भरें"
                                    },
                                    pattern: {
                                        value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                        message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("mobileNumber", e)}
                            />
                            {errors.mobileNumber && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.mobileNumber.message}</p>}
                        </div>
                        {/* व्हाट्सप्प नंबर */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Whatsapp No /व्हाट्सप्प नंबर"
                                placeholder="Whatsapp No"
                                type="number"
                                required="required"
                                {...register("whatsappNumber", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अंक भरें"
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "कृपया 10 अंक से अधिक न भरें"
                                    },
                                    pattern: {
                                        value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                        message: "कृपया मान्य व्हाट्सप्प नंबर दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("whatsappNumber", e)}
                            />
                            {errors.whatsappNumber && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.whatsappNumber.message}</p>}
                        </div>
                        {/* समाज का मेंबर कोड */}
                        <div className="sm:col-span-3">
                            <div className="sm:col-span-3">
                                <Input
                                    label="Samaj Code / समाज का मेंबर कोड"
                                    placeholder="Samaj Code"
                                    type="text"
                                    {...register("samajMemberCode", {

                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "कृपया 20 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("samajMemberCode", e)}
                                />
                                {errors.samajMemberCode && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.samajMemberCode.message}</p>}
                            </div>
                        </div>

                        {/* शहर */}
                        {/* <div className='flex w-full flex-col md:flex-row mt-2   justify-between gap-4 mx-2 '> */}


                        {/* countryName */}
                        {/* <div className="w-100 md:w-1/3 ">
                        <SearchDropDownCriteria
                            options={country}
                            setItem={setValueLocation}
                            search={'countryName'}
                            id={'countryID'}
                            setOptions={setState}
                            label={"Country"}
                            getValues={getValues}
                            alreadySelected={""}
                            style={'required text-black font-bold'}
                            register={register} // Pass the register function as a prop
                        />
                    </div> */}

                        {/* stateName */}
                        <div className="sm:col-span-3 ">
                            <SearchDropDownCriteria
                                options={state}
                                setItem={setValueLocation}
                                search={'stateName'}
                                id={'stateID'}
                                setOptions={setState}
                                label={"State / राज्य"}
                                getValues={getValues}
                                alreadySelected={""}
                                style={'required text-black font-bold'}
                                register={register}
                                dataFeildName={'State'} // Pass the register function as a prop
                            />
                        </div>


                        {/* cityName */}
                        <div className="sm:col-span-3 ">
                            <SearchDropDownCriteria
                                options={city}
                                setItem={setValueLocation}
                                search={'cityName'}
                                id={'cityID'}
                                setOptions={setState}
                                label={"City / शहर"}
                                getValues={getValues}
                                alreadySelected={""}
                                style={'required text-black font-bold'}
                                register={register}
                                dataFeildName={'City'} // Pass the register function as a prop
                            />
                        </div>




                        {/* </div> */}

                        {/* पता */}
                        <div className="col-span-full">
                            <Textarea
                                label="Address / पता"
                                placeholder="Address"
                                type="text"
                                required="required"

                                {...register("address", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 20,
                                        message: "कृपया कम से कम 20 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "कृपया 100 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("address", e)}
                            />

                        </div>

                    </div>





                    <FormHeading heading={'Address / पता'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">

                        {/* धर्मशाला बुकिंग तारीख */}
                        {/* <div className="sm:col-span-3">
                        <Input
                            label="धर्मशाला बुकिंग तारीख"
                            placeholder="धर्मशाला बुकिंग तारीख"
                            required="required"
                            type="date"
                            {...register("bookingDate", { required: "इस फ़ील्ड को भरना ज़रूरी है" })}
                            onChange={(e) => handleChange("bookingDate", e)}
                        />
                        {errors.bookingDate && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.bookingDate.message}</p>}
                    </div> */}

                        {/* धर्मशाला आगमन की तारीख */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Entry Date / धर्मशाला आगमन की तारीख"
                                placeholder="Entry Date"
                                required="required"
                                type="datetime-local"
                                {...register("checkInDateTime", { required: "इस फ़ील्ड को भरना ज़रूरी है" })}
                                onChange={(e) => handleChange("checkInDateTime", e)}
                            />
                            {errors.checkInDateTime && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.checkInDateTime.message}</p>}
                        </div>

                        {/* धर्मशाला छोड़ने की तारीख */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Leaving Date / धर्मशाला छोड़ने की तारीख"
                                placeholder="Leaving Date / धर्मशाला छोड़ने की तारीख"
                                required="required"
                                type="datetime-local"
                                {...register("checkOutDateTime", { required: "इस फ़ील्ड को भरना ज़रूरी है" })}
                                onChange={(e) => handleChange("checkOutDateTime", e)}
                            />
                            {errors.checkOutDateTime && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.checkOutDateTime.message}</p>}
                        </div>



                        {/* कुल लोगो की संख्या */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Total Persons / कुल लोगो की संख्या"
                                placeholder="Total Persons"
                                type="number"
                                required="required"
                                min="1"
                                max='20'
                                {...register("totalNumberOfPeople", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    min: {
                                        value: 1,
                                        message: "कृपया 1 या अधिक लोगो की संख्या दर्ज करें"
                                    },
                                    // maxLength: {
                                    //     value: 10,
                                    //     message: "कृपया 10 अंकों से अधिक न भरें"
                                    // }
                                })}
                                onChange={(e) => handleChange("totalNumberOfPeople", e)}
                            />
                            {errors.totalNumberOfPeople && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.totalNumberOfPeople.message}</p>}
                        </div>
                        {/* धर्मशाला अवधी दिन / घंटे */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Total Duration / धर्मशाला अवधी दिन / घंटे"
                                placeholder="Total Duration"
                                type="text"
                                required="required"
                                disabled
                                value={calculateDuration(getValues('checkInDateTime'), getValues('checkOutDateTime'))}
                                // onChange={(e) => setValue("bookingDurationDaysAndhours", e.target.value)}

                                onChange={(e) => handleChange("bookingDurationDaysAndhours", e)}
                                // value={calculateDuration(getValues('checkInDateTime'), getValues('checkOutDateTime'))}
                                {...register("bookingDurationDaysAndhours", {
                                    value: `${calculateDuration(getValues('checkInDateTime'), getValues('checkOutDateTime'))}`,
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                })}
                            // onChange={(e) => handleChange("days", e)}
                            />
                            {errors.bookingDurationDaysAndhours && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bookingDurationDaysAndhours.message}</p>}
                        </div>

                        {/* totalBookingAmount */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Total Booking Amount / बुकिंग राशि"
                                placeholder="Total Booking Amount / बुकिंग राशि"
                                type="number" // Change type to "text" to allow non-numeric characters
                                required="required"
                                minLength="2"
                                {...register("totalBookingAmount", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 2,
                                        message: "कृपया कम से कम 2 अंक भरें"
                                    },
                                    pattern: {
                                        value: /^\d+$/, // Regular expression to match only numeric characters
                                        message: "कृपया केवल अंक दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("totalBookingAmount", e)}
                            />
                            {errors.totalBookingAmount && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.totalBookingAmount.message}</p>}
                        </div>


                        {/* धर्मशाला अवधी घंटे */}
                        {/* <div className="sm:col-span-3">
                        <Input
                            label="धर्मशाला अवधी घंटे"
                            placeholder="धर्मशाला अवधी घंटे"
                            type="number"
                            required="required"
                            min="1"
                            {...register("hours", {
                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                min: {
                                    value: 1,
                                    message: "कृपया 1 या अधिक घंटे दर्ज करें"
                                },
                                // maxLength: {
                                //     value: 10,
                                //     message: "कृपया 10 अंकों से अधिक न भरें"
                                // }
                            })}
                            onChange={(e) => handleChange("hours", e)}
                        />
                        {errors.hours && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.hours.message}</p>}
                    </div> */}

                    </div>
                    {/* धर्मशाला की सुविधाएं */}
                    <div className="sm:col-span-3 mt-3 px-5 ">
                        <label className="block required font-extrabold text-sm  leading-6 text-gray-900">
                            धर्मशाला की सुविधाएं
                        </label>

                        <div className='flex flex-col items-start xl:flex-row gap-4'>

                            {suvida.map(item => (
                                <>
                                    <div className="">
                                        <CheackBox
                                            label={item.text}
                                            placeholder={item.text}
                                            name="reciveMethod"
                                            type="checkbox"
                                            value={item.suvidha}
                                            onChange={(e) => handleCheckboxChange(item.suvidha, e.target.checked)}

                                        />
                                        {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
                                    </div>
                                </>

                            ))}
                            {suvidhaError && <p id="username-error" class="text-red-500 text-xs mt-1 ">At Least One Required</p>}



                            {/* <div className="sm:col-span-3">
                            <CheackBox
                                label="बैंक ट्रांसफर"
                                placeholder="डोनेशन तारीख"
                                name="reciveMethod"
                                type="checkbox"

                                value="banktransfer"
                                {...register("reciveMethod")}
                                onChange={(e) => handleChange("reciveMethod", e)}
                            />
                            {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>}
                        </div>
                        <div className="sm:col-span-3">
                            <CheackBox
                                label=" कॅश"
                                placeholder=" कॅश"
                                type="checkbox"
                                name="reciveMethod"
                               
                                value="cash"
                                onChange={(e) => handleChange("reciveMethod", e)}
                                {...register("reciveMethod",)}
                            />
                            {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>}
                        </div> */}
                        </div>
                    </div>
                    {/* *********************************************************************************************** */}

                    {/* ***************************************************************************************************                */}




                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}


// {
//     totalBookingAmoutADV: {},
//     AdvancePaidAmountADV: {},
//     paymentModeADV: { cash, upi, bankTransfer, bycheque, it shout be array[]},
//     transactionDetailsADV: { cashAmountADV, upiTransNumADV, upiTranDateADV, bankTransferNumADV, bankTranferDateADV, chqNuADV, chqdateADV },
//     depositToAccountNumADV: { min 15 digit }
//     depositToAccountNameADV: {}
//     ADVDepositDateTime: {}

//     balancePaidAmountBAL: {},
//     paymentModeABAl: { cash, upi, bankTransfer, bycheque, it shout be array[]},
//     transactionDetailsBAl: { cashAmountBAL, upiTransNumBAL, upiTranDateBAL, bankTransferNumBAL, bankTranferDateBAL, chqNuBAL, chqdateBAL },
//     depositToAccountNUmBAL: { min 15 digit }
//     depositToAccountNameBAL: {}
//     BAlDepositDateTime: {}
// }

// {/* <FormHeading heading={'AdvancePaidAmountADV'} />
//                 <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
//                     {/* totalBookingAmount */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Total Booking Amount"
//                             placeholder="Total Booking Amount"
//                             type="number"
//                             required="required"
//                             minLength="2"

//                             {...register("totalBookingAmount", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 2 अंक भरें"
//                                 },
//                                 // maxLength: {
//                                 //     value: 2,
//                                 //     message: "कृपया 10 अंक से अधिक न भरें"
//                                 // },
//                                 // pattern: {
//                                 //     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                 //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 // }
//                             })}
//                             onChange={(e) => handleChange("totalBookingAmount", e)}
//                         />
//                         {errors.totalBookingAmount && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.totalBookingAmount.message}</p>}
//                     </div>

//                     {/* AdvancePaidAmountADV: */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Advance Paid Amount"
//                             placeholder="Advance Paid Amount"
//                             type="number"
//                             required="required"
//                             minLength="10"
//                             maxLength="10"
//                             {...register("advancePaidAmount", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 10 अंक भरें"
//                                 },
//                                 maxLength: {
//                                     value: 2,
//                                     message: "कृपया 10 अंक से अधिक न भरें"
//                                 },
//                                 // pattern: {
//                                 //     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                 //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 // }
//                             })}
//                             onChange={(e) => handleChange("advancePaidAmount", e)}
//                         />
//                         {errors.advancePaidAmount && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.advancePaidAmount.message}</p>}
//                     </div>

//                     {/* payment mode */}
//                     <div className="sm:col-span-6 ">
//                         <label className="block required text-sm font-extrabold leading-6 text-gray-900">
//                             Advance Payment Mode
//                         </label>

//                         <div className='w-full flex  items-start justify-start flex-col'>
//                             <div className="">
//                                 <CheackBox
//                                     label="बैंक ट्रांसफर"
//                                     placeholder="डोनेशन तारीख"
//                                     name="bankTransfer"
//                                     type="checkbox"
//                                     value="banktransfer"
//                                     {...register("bankTransfer")}
//                                     onChange={(e) => setIsBankTransferChecked(e.target.checked)}
//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>
//                             {/* cheque number */}

//                             {isBankTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//                                 <div className="sm:col-span-3 w-[100%] m">
//                                     <Input
//                                         label="Bank Transfer number"
//                                         placeholder="Bank Transfer number"
//                                         type="number"
//                                         required="required"
//                                         {...register("bankTransferChequeNum", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             minLength: {
//                                                 value: 15,
//                                                 message: "कृपया कम से कम 15 अंक भरें"
//                                             },
//                                             maxLength: {
//                                                 value: 15,
//                                                 message: "कृपया 15 अंक से अधिक न भरें"
//                                             },
//                                             pattern: {
//                                                 value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                                 message: "कृपया मान्य  नंबर दर्ज करें"
//                                             }
//                                         })}
//                                         onChange={(e) => handleChange("bankTransferChequeNum", e)}
//                                     />
//                                     {errors.bankTransferChequeNum && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bankTransferChequeNum.message}</p>}
//                                 </div>
//                                 {/* cheque date */}
//                                 <div className="sm:col-span-3 w-[100%] ">
//                                     <Input
//                                         label="Transfer Date"
//                                         placeholder="Transfer Date"
//                                         type="date"
//                                         required="required"
//                                         {...register("bankTransferDate", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             // minLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया कम से कम 15 अंक भरें"
//                                             // },
//                                             // maxLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया 15 अंक से अधिक न भरें"
//                                             // },
//                                             // pattern: {
//                                             //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                             //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                             // }
//                                         })}
//                                         onChange={(e) => handleChange("bankTransferDate", e)}
//                                     />
//                                     {errors.bankTransferDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bankTransferDate.message}</p>}
//                                 </div>
//                             </div>}

//                             {/* कॅश */}
//                             <div className="">
//                                 <CheackBox
//                                     label=" कॅश"
//                                     placeholder=" कॅश"
//                                     type="checkbox"
//                                     name="reciveMethod"
//                                     value="cash"
//                                     onChange={(e) => handleChange("reciveMethod", e)}
//                                     {...register("reciveMethod",)}
//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>

//                             {/* UPI */}
//                             <div className="">
//                                 <CheackBox
//                                     label="यु पि आए"
//                                     placeholder="यु पि आए"
//                                     type="checkbox"
//                                     name="upiTranfer"

//                                     {...register("upiTranfer",)}
//                                     onChange={(e) => setIsUpiTransferChecked(e.target.checked)}

//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>
//                             {isUpiTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//                                 <div className="sm:col-span-3 w-[100%] m">
//                                     <Input
//                                         label="UPI Transaction ID"
//                                         placeholder="UPI Transaction ID"
//                                         type="number"
//                                         required="required"
//                                         {...register("upiTransactionID", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             minLength: {
//                                                 value: 15,
//                                                 message: "कृपया कम से कम 15 अंक भरें"
//                                             },
//                                             maxLength: {
//                                                 value: 15,
//                                                 message: "कृपया 15 अंक से अधिक न भरें"
//                                             },
//                                             pattern: {
//                                                 value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                                 message: "कृपया मान्य  नंबर दर्ज करें"
//                                             }
//                                         })}
//                                         onChange={(e) => handleChange("upiTransactionID", e)}
//                                     />
//                                     {errors.upiTransactionID && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.upiTransactionID.message}</p>}
//                                 </div>
//                                 {/* cheque date */}
//                                 <div className="sm:col-span-3 w-[100%] ">
//                                     <Input
//                                         label="UPI Transaction Date"
//                                         placeholder="UPI Transaction Date"
//                                         type="date"
//                                         required="required"
//                                         {...register("upiTransactionDate", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             // minLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया कम से कम 15 अंक भरें"
//                                             // },
//                                             // maxLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया 15 अंक से अधिक न भरें"
//                                             // },
//                                             // pattern: {
//                                             //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                             //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                             // }
//                                         })}
//                                         onChange={(e) => handleChange("upiTransactionDate", e)}
//                                     />
//                                     {errors.upiTransactionDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.upiTransactionDate.message}</p>}
//                                 </div>
//                             </div>}
//                             {/* bycheque 10Digit */}
//                             <div className="">
//                                 <CheackBox
//                                     label=" चेक "
//                                     placeholder=" चेक "
//                                     type="checkbox"
//                                     name="reciveMethod"
//                                     {...register("chequeTansger",)}
//                                     onChange={(e) => setIsChequeTransferChecked(e.target.checked)}

//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>
//                             {isChequeTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//                                 <div className="sm:col-span-3 w-[100%] m">
//                                     <Input
//                                         label="Cheque Transaction ID"
//                                         placeholder="Cheque Transaction ID"
//                                         type="number"
//                                         required="required"
//                                         {...register("chequeTransactionID", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             minLength: {
//                                                 value: 15,
//                                                 message: "कृपया कम से कम 15 अंक भरें"
//                                             },
//                                             maxLength: {
//                                                 value: 15,
//                                                 message: "कृपया 15 अंक से अधिक न भरें"
//                                             },
//                                             pattern: {
//                                                 value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                                 message: "कृपया मान्य  नंबर दर्ज करें"
//                                             }
//                                         })}
//                                         onChange={(e) => handleChange("chequeTransactionID", e)}
//                                     />
//                                     {errors.chequeTransactionID && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.chequeTransactionID.message}</p>}
//                                 </div>
//                                 {/* cheque date */}
//                                 <div className="sm:col-span-3 w-[100%] ">
//                                     <Input
//                                         label="cheque Transaction Date"
//                                         placeholder="cheque Transaction Date"
//                                         type="date"
//                                         required="required"
//                                         {...register("chequeTransactionDate", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             // minLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया कम से कम 15 अंक भरें"
//                                             // },
//                                             // maxLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया 15 अंक से अधिक न भरें"
//                                             // },
//                                             // pattern: {
//                                             //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                             //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                             // }
//                                         })}
//                                         onChange={(e) => handleChange("chequeTransactionDate", e)}
//                                     />
//                                     {errors.chequeTransactionDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.chequeTransactionDate.message}</p>}
//                                 </div>
//                             </div>}
//                         </div>
//                     </div>


//                     {/* depositToAccountNumADV: */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Advance deposit account number"
//                             placeholder="Advance deposit account number"
//                             type="number"
//                             required="required"
//                             minLength="10"
//                             maxLength="10"
//                             {...register("advanceDepositAccountNumber", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 // minLength: {
//                                 //     value: 2,
//                                 //     message: "कृपया कम से कम 10 अंक भरें"
//                                 // },
//                                 // maxLength: {
//                                 //     value: 2,
//                                 //     message: "कृपया 10 अंक से अधिक न भरें"
//                                 // },
//                                 // pattern: {
//                                 //     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                 //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 // }
//                             })}
//                             onChange={(e) => handleChange("advanceDepositAccountNumber", e)}
//                         />
//                         {errors.advanceDepositAccountNumber && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.advanceDepositAccountNumber.message}</p>}
//                     </div>

//                     {/* depositToAccountNameADV */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Advance deposit account Holder Name"
//                             placeholder="Advance deposit account Holder Name"
//                             type="text"
//                             required="required"
//                             minLength="10"
//                             maxLength="10"
//                             {...register("advanceDepositAccountName", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 2 भरें"
//                                 },
//                                 maxLength: {
//                                     value: 2,
//                                     message: "कृपया 10 से अधिक न भरें"
//                                 },
//                                 pattern: {
//                                     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 }
//                             })}
//                             onChange={(e) => handleChange("advanceDepositAccountName", e)}
//                         />
//                         {errors.advanceDepositAccountName && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.advanceDepositAccountName.message}</p>}
//                     </div>


//                 </div>

//                 <FormHeading heading={'BalancePaidAmountBAl'} />
//                 <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
//                     {/* Total Balance Amount */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Total Balance Amount"
//                             placeholder="Total Balance Amount"
//                             type="number"
//                             required="required"
//                             minLength="2"
//                             value={getValues('totalBookingAmount') - getValues('advancePaidAmount')}
//                             disable={true}
//                             {...register("totalBalanceAmount", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 disabled: true,
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 2 अंक भरें"
//                                 },
//                                 // maxLength: {
//                                 //     value: 2,
//                                 //     message: "कृपया 10 अंक से अधिक न भरें"
//                                 // },
//                                 // pattern: {
//                                 //     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                 //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 // }
//                             })}
//                             onChange={(e) => handleChange("totalBalanceAmount", e)}
//                         />
//                         {errors.totalBalanceAmount && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.totalBalanceAmount.message}</p>}
//                     </div>

//                     {/* AdvancePaidAmountADV: */}

//                     {/* <div className="sm:col-span-3">
//                         <Input
//                             label="Advance Paid Amount"
//                             placeholder="Advance Paid Amount"
//                             type="number"
//                             required="required"
//                             minLength="10"
//                             maxLength="10"
//                             {...register("advancePaidAmount", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 10 अंक भरें"
//                                 },
//                                 maxLength: {
//                                     value: 2,
//                                     message: "कृपया 10 अंक से अधिक न भरें"
//                                 },
//                                 // pattern: {
//                                 //     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                 //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 // }
//                             })}
//                             onChange={(e) => handleChange("advancePaidAmount", e)}
//                         />
//                         {errors.advancePaidAmount && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.advancePaidAmount.message}</p>}
//                     </div> */}

//                     {/* payment mode */}
//                     <div className="sm:col-span-6 ">
//                         <label className="block required text-sm font-extrabold leading-6 text-gray-900">
//                             Advance Payment Mode
//                         </label>

//                         <div className='w-full flex  items-start justify-start flex-col'>
//                             <div className="">
//                                 <CheackBox
//                                     label="बैंक ट्रांसफर"
//                                     placeholder="डोनेशन तारीख"
//                                     name="bankTransfer"
//                                     type="checkbox"
//                                     value="banktransfer"
//                                     {...register("bankTransfer")}
//                                     onChange={(e) => setIsBankTransferChecked(e.target.checked)}
//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>
//                             {/* cheque number */}

//                             {isBankTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//                                 <div className="sm:col-span-3 w-[100%] m">
//                                     <Input
//                                         label="Bank Transfer number"
//                                         placeholder="Bank Transfer number"
//                                         type="number"
//                                         required="required"
//                                         {...register("bankTransferChequeNum", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             minLength: {
//                                                 value: 15,
//                                                 message: "कृपया कम से कम 15 अंक भरें"
//                                             },
//                                             maxLength: {
//                                                 value: 15,
//                                                 message: "कृपया 15 अंक से अधिक न भरें"
//                                             },
//                                             pattern: {
//                                                 value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                                 message: "कृपया मान्य  नंबर दर्ज करें"
//                                             }
//                                         })}
//                                         onChange={(e) => handleChange("bankTransferChequeNum", e)}
//                                     />
//                                     {errors.bankTransferChequeNum && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bankTransferChequeNum.message}</p>}
//                                 </div>
//                                 {/* cheque date */}
//                                 <div className="sm:col-span-3 w-[100%] ">
//                                     <Input
//                                         label="Transfer Date"
//                                         placeholder="Transfer Date"
//                                         type="date"
//                                         required="required"
//                                         {...register("bankTransferDate", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             // minLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया कम से कम 15 अंक भरें"
//                                             // },
//                                             // maxLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया 15 अंक से अधिक न भरें"
//                                             // },
//                                             // pattern: {
//                                             //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                             //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                             // }
//                                         })}
//                                         onChange={(e) => handleChange("bankTransferDate", e)}
//                                     />
//                                     {errors.bankTransferDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.bankTransferDate.message}</p>}
//                                 </div>
//                             </div>}

//                             {/* कॅश */}
//                             <div className="">
//                                 <CheackBox
//                                     label=" कॅश"
//                                     placeholder=" कॅश"
//                                     type="checkbox"
//                                     name="reciveMethod"
//                                     value="cash"
//                                     onChange={(e) => handleChange("reciveMethod", e)}
//                                     {...register("reciveMethod",)}
//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>

//                             {/* UPI */}
//                             <div className="">
//                                 <CheackBox
//                                     label="यु पि आए"
//                                     placeholder="यु पि आए"
//                                     type="checkbox"
//                                     name="upiTranfer"

//                                     {...register("upiTranfer",)}
//                                     onChange={(e) => setIsUpiTransferChecked(e.target.checked)}

//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>
//                             {isUpiTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//                                 <div className="sm:col-span-3 w-[100%] m">
//                                     <Input
//                                         label="UPI Transaction ID"
//                                         placeholder="UPI Transaction ID"
//                                         type="number"
//                                         required="required"
//                                         {...register("upiTransactionID", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             minLength: {
//                                                 value: 15,
//                                                 message: "कृपया कम से कम 15 अंक भरें"
//                                             },
//                                             maxLength: {
//                                                 value: 15,
//                                                 message: "कृपया 15 अंक से अधिक न भरें"
//                                             },
//                                             pattern: {
//                                                 value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                                 message: "कृपया मान्य  नंबर दर्ज करें"
//                                             }
//                                         })}
//                                         onChange={(e) => handleChange("upiTransactionID", e)}
//                                     />
//                                     {errors.upiTransactionID && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.upiTransactionID.message}</p>}
//                                 </div>
//                                 {/* cheque date */}
//                                 <div className="sm:col-span-3 w-[100%] ">
//                                     <Input
//                                         label="UPI Transaction Date"
//                                         placeholder="UPI Transaction Date"
//                                         type="date"
//                                         required="required"
//                                         {...register("upiTransactionDate", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             // minLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया कम से कम 15 अंक भरें"
//                                             // },
//                                             // maxLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया 15 अंक से अधिक न भरें"
//                                             // },
//                                             // pattern: {
//                                             //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                             //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                             // }
//                                         })}
//                                         onChange={(e) => handleChange("upiTransactionDate", e)}
//                                     />
//                                     {errors.upiTransactionDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.upiTransactionDate.message}</p>}
//                                 </div>
//                             </div>}
//                             {/* bycheque 10Digit */}
//                             <div className="">
//                                 <CheackBox
//                                     label=" चेक "
//                                     placeholder=" चेक "
//                                     type="checkbox"
//                                     name="reciveMethod"
//                                     {...register("chequeTansger",)}
//                                     onChange={(e) => setIsChequeTransferChecked(e.target.checked)}

//                                 />
//                                 {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
//                             </div>
//                             {isChequeTransferChecked && <div className={`block  md:flex flex-wrap w-full  `}>
//                                 <div className="sm:col-span-3 w-[100%] m">
//                                     <Input
//                                         label="Cheque Transaction ID"
//                                         placeholder="Cheque Transaction ID"
//                                         type="number"
//                                         required="required"
//                                         {...register("chequeTransactionID", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             minLength: {
//                                                 value: 15,
//                                                 message: "कृपया कम से कम 15 अंक भरें"
//                                             },
//                                             maxLength: {
//                                                 value: 15,
//                                                 message: "कृपया 15 अंक से अधिक न भरें"
//                                             },
//                                             pattern: {
//                                                 value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                                 message: "कृपया मान्य  नंबर दर्ज करें"
//                                             }
//                                         })}
//                                         onChange={(e) => handleChange("chequeTransactionID", e)}
//                                     />
//                                     {errors.chequeTransactionID && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.chequeTransactionID.message}</p>}
//                                 </div>
//                                 {/* cheque date */}
//                                 <div className="sm:col-span-3 w-[100%] ">
//                                     <Input
//                                         label="cheque Transaction Date"
//                                         placeholder="cheque Transaction Date"
//                                         type="date"
//                                         required="required"
//                                         {...register("chequeTransactionDate", {
//                                             required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                             // minLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया कम से कम 15 अंक भरें"
//                                             // },
//                                             // maxLength: {
//                                             //     value: 15,
//                                             //     message: "कृपया 15 अंक से अधिक न भरें"
//                                             // },
//                                             // pattern: {
//                                             //     value: /^[0-9]\d{14}$/, // Regular expression to match Indian mobile numbers
//                                             //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                             // }
//                                         })}
//                                         onChange={(e) => handleChange("chequeTransactionDate", e)}
//                                     />
//                                     {errors.chequeTransactionDate && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.chequeTransactionDate.message}</p>}
//                                 </div>
//                             </div>}
//                         </div>
//                     </div>


//                     {/* depositToAccountNumADV: */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Balance deposit account number"
//                             placeholder="Balance deposit account number"
//                             type="number"
//                             required="required"
//                             minLength="2"
//                             maxLength="10"
//                             {...register("advanceDepositAccountNumber", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 10 अंक भरें"
//                                 },
//                                 maxLength: {
//                                     value: 2,
//                                     message: "कृपया 10 अंक से अधिक न भरें"
//                                 },
//                                 // pattern: {
//                                 //     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                 //     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 // }
//                             })}
//                             onChange={(e) => handleChange("advanceDepositAccountNumber", e)}
//                         />
//                         {errors.advanceDepositAccountNumber && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.advanceDepositAccountNumber.message}</p>}
//                     </div>

//                     {/* depositToAccountNameADV */}
//                     <div className="sm:col-span-3">
//                         <Input
//                             label="Balance deposit account Holder Name"
//                             placeholder="Balance deposit account Holder Name"
//                             type="text"
//                             required="required"
//                             minLength="2"
//                             maxLength="10"
//                             {...register("balanceDepositAccountName", {
//                                 required: "इस फ़ील्ड को भरना ज़रूरी है",
//                                 minLength: {
//                                     value: 2,
//                                     message: "कृपया कम से कम 2 भरें"
//                                 },
//                                 maxLength: {
//                                     value: 2,
//                                     message: "कृपया 10 से अधिक न भरें"
//                                 },
//                                 pattern: {
//                                     value: /^[0-9]\d{2}$/, // Regular expression to match Indian mobile numbers
//                                     message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
//                                 }
//                             })}
//                             onChange={(e) => handleChange("balanceDepositAccountName", e)}
//                         />
//                         {errors.balanceDepositAccountName && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.balanceDepositAccountName.message}</p>}
//                     </div>


//                 </div> */}
