import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getSAchivement } from '../../../service/Services'
import { AchivementCard } from '../../../cards'

import AdminButton from '../../../Forms/AdminButtons'

export default function Achievements() {
    const [achivements, setAchivement] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await getSAchivement()
                if (res?.success) {
                    setAchivement(res?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    if (loading) {
        return <p className=' font-bold'>Loading...</p>
    }

    return (
        <div>
            <AdminButton text="उपलब्धि जोड़ें" className=" bg-blue-950 text-white" url="/admin/achivement" />

            <div className=' w-full text-end p-4'>
                {/* <Link className=' btn bg-blue-950 text-white' to={'/dashboard/achivement'}>Add Achivement</Link> */}

                <div className='flex  flex-wrap gap-4 justify-center '>
                    {achivements.map((achivement, index) => (
                        <div className='w-[350px]' key={index}><AchivementCard achivement={achivement} /></div>
                    ))}

                </div>
                {achivements.length == 0 && <h1>No Event In this Month</h1>}

            </div>
        </div>
    )
}
