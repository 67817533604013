
import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormHeading from './FormHeading'
import { useForm } from 'react-hook-form'
import { CheackBox, Input, InputDate, PhotoUpload, SearchCriteria, SearchDropDownCriteria, Textarea } from '../FormsInputes.js'

import TextToSpeech from './TextToSpeech.js';
import { addNews, getSateByCountryID } from '../service/Services.js';
import LoadingModal from './LoadingModel.js';
import Swal from 'sweetalert2';
import { get_city, get_country, get_state } from '../ApiConfig.js';
import axios from 'axios';
import { HeaderBoarder } from '../Components/index.js';
import AdminButton from './AdminButtons.js';



const samaj = [
    { text: 'porwal samaj indor', id: 1 },
    { text: 'porwal samaj Bhopal', id: 2 },
    { text: 'porwal samaj Shendhwa', id: 3 },
    { text: 'porwal samaj Balwani', id: 4 }
];


export default function ShokSamachar() {

    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();
    const [selectedEvent, setSelectedEvent] = useState('');
    const [state, setState] = useState([])
    const [country, setCountry] = useState([])
    const [city, setCity] = useState([])
    const [value, setValueLocation] = useState([]);

    // const [stateAndCity, setStateAndCity] = useState([{ stateName: "", cityName: '', cityId: '', }])
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const [samajShow, setSamajShow] = useState([]);

    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };
    const handleRadioChange = (e) => {
        setSelectedEvent(e.target.value);
    };
    const submit = async (data) => {
        try {
            console.log(data)
            // Validate all required fields
            if (!data.news || !data.newsReportedBy || !data.personName || !selectedEvent || !samajShow || !data.City || !data.State || !data.Country) {
                throw new Error('All fields are required');
            }
            setLoading(true);

            // Create FormData object
            const formData = new FormData();

            // Append valid data to FormData
            for (let key in data) {
                if (data.hasOwnProperty(key) && typeof data[key] !== 'object') {
                    formData.append(key, data[key]);
                }
            }

            formData.append('event', selectedEvent);
            formData.append('samajToShow', JSON.stringify(samajShow));
            formData.append('city', JSON.stringify(data.City));
            formData.append('state', JSON.stringify(data.State));
            formData.append('country', JSON.stringify(data.Country));
            formData.append('shokProfile', data.shokProfile);


            // Call addNews API
            const isNewsAdded = await addNews(formData);

            // Check if news added successfully
            if (isNewsAdded?.success) {
                Swal.fire({
                    title: isNewsAdded.success,
                    text: isNewsAdded.message,
                    icon: 'success',
                });
                reset()
                setSelectedEvent("")
                setSamajShow([])
                setValueLocation([])
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const onSamajSelectChange = (e) => {
        const { value } = e.target;
        const { text } = JSON.parse(value)
        console.log(
            text
        )
        setSamajShow((prevSamajShow) => prevSamajShow?.text?.includes(text) ? prevSamajShow : [...prevSamajShow, JSON.parse(value)]);
    };

    const handleRemoveSamajShow = (indexToRemove) => {
        setSamajShow((prevSamajShow) => prevSamajShow.filter((_, index) => index !== indexToRemove));
    };

    useLayoutEffect(() => {
        (async () => {
            const res = await axios(`${get_country}`)
            console.log(res?.data?.data);
            setCountry(pre => res?.data?.data)
        }
        )()
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            console.log(value);
            if (value.item === 'countryName') {
                const res = await axios.get(`${get_state}/${value?.id}`);
                setState(res?.data?.data || []);
            } else if (value.item === 'stateName') {
                const res = await axios.get(`${get_city}/${value?.id}`);
                setCity(res?.data?.data || []);
            }
        };
        fetchData();
    }, [value]);

    return (
        <>
            <HeaderBoarder heading={"शोक समाचार"} />
            <LoadingModal show={isLoading} text={'Sending...'} />

            <div className='w-100  md:px-10 '>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'Details / जानकारी'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:grid-cols-1 xl:grid-cols-6  mx-2 md:mx-5">

                        {/* "heading" */}
                        {/* <div className="sm:col-span-3">
                            <Input
                                label="Heading"
                                placeholder="Heading"
                                type="text"
                                required="required"

                                {...register("heading", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 1000,
                                        message: "कृपया 1000 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("heading", e)}
                            />
                            {errors.heading && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.heading.message}</p>}
                        </div> */}



                        {/* Dathe person name */}
                        <div className="sm:col-span-3 ">
                            <Input
                                label="Death person name / शांत होने वाले का नाम"
                                placeholder="शांत होने वाले का नाम"
                                type="text"
                                required="required"
                                {...register("personName", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: "कृपया 40 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("personName", e)}
                            />
                            {errors.personName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.personName.message}</p>}
                        </div>
                    </div>

                    {/* News Repoter Name */}
                    <div className="col-span-full mx-2 md:mx-5">
                        <Textarea
                            label="News Repoter Name / समाचार रिपोर्ट करने वाले का नाम"
                            placeholder="समाचार रिपोर्ट करने वाले का नाम"
                            type="text"
                            required="required"

                            {...register("newsReportedBy", {
                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                minLength: {
                                    value: 3,
                                    message: "कृपया कम से कम 3 अक्षर भरें"
                                },
                                maxLength: {
                                    value: 100,
                                    message: "कृपया 100 अक्षरों से कम भरें"
                                }
                            })}
                            onChange={(e) => handleChange("newsReportedBy", e)}
                        />
                        {errors.newsReportedBy && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.newsReportedBy.message}</p>}
                    </div>


                    <div className='flex w-full flex-col md:flex-row mt-2   justify-between gap-4 mx-2 '>


                        {/* countryName */}
                        <div className="w-100 md:w-1/3 ">
                            <SearchDropDownCriteria
                                options={country}
                                setItem={setValueLocation}
                                search={'countryName'}
                                id={'countryID'}
                                setOptions={setState}
                                label={"Country / देश"}
                                getValues={getValues}
                                alreadySelected={""}
                                style={'required text-black font-bold'}
                                register={register}
                                dataFeildName={'Country'}// Pass the register function as a prop
                            />
                        </div>

                        {/* stateName */}
                        <div className="w-100 md:w-1/3 ">
                            <SearchDropDownCriteria
                                options={state}
                                setItem={setValueLocation}
                                search={'stateName'}
                                id={'stateID'}
                                setOptions={setState}
                                label={"State / राज्य"}
                                getValues={getValues}
                                alreadySelected={""}
                                style={'required text-black font-bold'}
                                register={register}
                                dataFeildName={'State'} // Pass the register function as a prop
                            />
                        </div>


                        {/* cityName */}
                        <div className="w-100 md:w-1/3 ">
                            <SearchDropDownCriteria
                                options={city}
                                setItem={setValueLocation}
                                search={'cityName'}
                                id={'cityID'}
                                setOptions={setState}
                                label={"City / शहर"}
                                getValues={getValues}
                                alreadySelected={""}
                                style={'required text-black font-bold'}
                                register={register}
                                dataFeildName={'City'} // Pass the register function as a prop
                            />
                        </div>




                    </div>


                    <div className="mt-5">
                        <FormHeading heading={'Event / आयोजन'} />
                    </div>
                    <div className='sm:col-span-12  flex flex-col items-start   my-5 mx-2 md:mx-5'>
                        {/* "event" */}
                        <div className="  ">
                            <CheackBox
                                label="Uthawana / उठावना"
                                placeholder="उठावना"
                                type="radio"
                                name="event"
                                value="उठावना"
                                // required="required"
                                checked={selectedEvent === "उठावना"}
                                onChange={handleRadioChange}
                            />
                            {errors.event && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.event.message}</p>}
                        </div>
                        <div className=" ">
                            <CheackBox
                                label="Shok Samachar / शोक समाचार"
                                placeholder="शोक समाचार"
                                type="radio"
                                value="शोक समाचार"
                                name="event"
                                required="required"
                                checked={selectedEvent === "शोक समाचार"}
                                onChange={handleRadioChange}
                            />
                            {errors.event && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.event.message}</p>}
                        </div>
                    </div>
                    {/* </div> */}

                    <div className={` ${samajShow.length > 0 ? 'flex' : 'hidden'} px-2 h-100 flex-wrap flex gap-2 rounded-md border-0 py-1.5 p-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}>
                        {samajShow.map((item, index) => (
                            <div className='text-center rounded-md bg-blue-500 w-max px-4 font-semibold text-white' key={index}>
                                {item.text}
                                <span className='ml-5 cursor-pointer' onClick={() => handleRemoveSamajShow(index)}>X</span>
                            </div>
                        ))}
                    </div>

                    <div className='sm:col-span-3 mx-2 md:mx-5 '>
                        <label className=' font-extrabold'>Samaj To Show / जिस समाज में बताना है</label>
                        {/* <div className='sm:col-span-3 ps-2'> */}
                        <select onChange={onSamajSelectChange} className='block ${className} px-2 w-full md:w-[48%] mt-2  rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                            {/* <option value={{ text: 'porwal samaj indor', id: 1 }}>porwal samaj indor</option>
                        <option value={{ text: 'porwal samaj Bhopal', id: 2 }}>porwal samaj Bhopal</option>
                        <option value={{ text: 'porwal samaj Shendhwa', id: 3 }}>porwal samaj Shendhwa</option>
                        <option value={{ text: 'porwal samaj Balwani', id: 4 }}>porwal samaj Balwani</option> */}
                            {samaj.map(option => (
                                <option key={option.id} value={JSON.stringify(option)} >
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>


                    {/* ************************************************************* */}

                    <div className="col-span-full mx-2 md:mx-5">
                        <Textarea
                            label="News / समाचार "
                            placeholder="News / समाचार  "
                            type="text"
                            {...register("news", {
                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                // value: `${formData?.permanentAddress}`,
                                minLength: {
                                    value: 10,
                                    message: "कृपया कम से कम 20 अक्षर भरें"
                                },
                                maxLength: {
                                    value: 3000,
                                    message: "कृपया 3000 अक्षरों से कम भरें"
                                }
                            })}
                            onChange={(e) => handleChange("news", e)}
                        />
                        {errors.news && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.news.message}</p>}

                    </div>
                    {/* <TextToSpeech text={getValues('news')} /> */}
                    <div className='flex flex-wrap justify-around mt-5 mx-2 md:mx-5'>
                        <PhotoUpload
                            label="शांत होने वाले व्यक्ति का फोटो"
                            accept="image/*"
                            type="file"
                            name="shokProfile"
                            required="required"
                            // value={formData?.profilePhoto}
                            className="sr-only"
                            register={register}
                            setValue={setValue}
                        />
                    </div>

                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                        {!isLoading ? <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button> : <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sending...
                        </button>}
                    </div>
                </form>

            </div>
        </>
    )
}

