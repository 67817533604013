import React from 'react';
import callporwal from "../assets/dashbordBtn/callporwal.png"
import chatporwal from "../assets/dashbordBtn/chatporwal.png"
import messageporwal from "../assets/dashbordBtn/messageporwal.png"
import viewporwal from "../assets/dashbordBtn/viewporwal.png"
import adhaksh from "../assets/adhaksh.jpeg"


function Card() {
    return (
        <div className="container">
            <div>
                <div className="card">
                    <div className="card-header flex  justify-center bg-orange-600" style={{ backgroundImage: `url(htts://images.unsplash.com/photo-1540228232483-1b64a7024923?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80)` }}>
                        <h1 className='text-white mt-2 text-[18px] '>श्री जांगडा पोरवाल पंचायती सभा</h1>
                        <div className="card-photo ">
                            <img src="https://demos.creative-tim.com/impact-design-system-pro/docs/assets/img/team/6.jpg" alt="" />
                            {/* <h3 className="card-name ">रामदयाल रतनलाल फरक्या </h3> */}

                        </div>
                    </div>
                    <div className="card-body bg-white ">
                        <div className='w-100 flex justify-center flex-col'>
                            <h3 className="card-name">रामदयाल रतनलाल फरक्या </h3>
                            <p className=" card-name text-yellow-300">अध्यक्ष </p>
                        </div>
                        {/* <div className="card-button">
                            <button className="btn btn-primary">Massage</button>
                            <button className="btn btn-outline-primary">Following</button>
                        </div> */}
                        <div className='flex justify-between gap-2 mt-2 text-white'>
                            <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className=' h-16' src={callporwal} alt="Call" /> <span className='text-sm leading-7 font-bold tracking-tight '>Call</span></p>
                            <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-12' src={chatporwal} alt="Chat" /> <span className='text-sm leading-7 font-bold tracking-tight '>Chat</span></p>
                            <p className='flex flex-col items-center cursor-pointer'> <img className='h-12' src={messageporwal} alt="Message" onClick={() => window.location.href = `sms:${9827033232}`} /><span className='text-sm leading-7 font-bold tracking-tight '> Message</span></p>
                            <p className='flex flex-col items-center cursor-pointer'> <img className='h-12' src={viewporwal} alt="View" /><span className='text-sm leading-7 font-bold tracking-tight '> View</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;
