import {
    ashokmeahta,
    ajayashok,
    amitmehata,
    arunporwal,
    devendramehata,
    govindgupta,
    gulabraogupta,
    hemlataprahlad,
    kamalghariya,
    kavitaramesh,
    krushnakant,
    maheshporwal,
    manjudipak,
    mayankratnawat,
    priyankagupta,
    rahulgopal,
    rajkumarmehata,
    rohitporwal,
    santoshmehata,
    shamsundarporwal,
    adhaksh,
    nileshporwal,
    ramshwarupporwal,
    shreerammandliya,
    mohandhanotiya
} from "../assets/CommitteeMember/index"



const CommitteeMembers = [
    {
        member_name: "रामदयाल रतनलाल फरक्या",
        member_profile: adhaksh,
        member_post: "अध्यक्ष",
        department: "गोत्र : कामरिया",
        mobile_number: "9827033232",
        whatsapp_number: "9827033232"
    },
    {
        member_name: "इंजि. अशोक मेहता",
        member_profile: ashokmeahta,
        member_post: "सचिव",
        department: "गोत्र : कामरिया",
        mobile_number: "9039387974",
        whatsapp_number: "9039387974"
    },
    {
        member_name: "कृष्णकांत मुजावदिया (मेलखेड़ा वाला)",
        member_profile: krushnakant,
        member_post: "उपाध्यक्ष",
        department: "Customer Support",
        mobile_number: "9425063773",
        whatsapp_number: "9425063773"
    },
    {
        member_name: "रामस्वरुप पोरवाल(कालु)",
        member_profile: ramshwarupporwal,
        member_post: "उपाध्यक्ष",
        department: "गोत्र : कामरिया",
        mobile_number: "9302103713",
        whatsapp_number: "9302103713"
    },


    {
        member_name: "बलराम गुप्ता",
        member_profile: gulabraogupta,
        member_post: "कोशाध्यक्ष",
        department: "Quality Assurance",
        mobile_number: "9827067535",
        whatsapp_number: "9827067535"
    },
    {
        member_name: "संतोष मेहता धरम कांटा वाला",
        member_profile: santoshmehata,
        member_post: "सहसचिव",
        department: "Finance",
        mobile_number: "9826037585",
        whatsapp_number: "9826037585"
    },
    {
        member_name: "अमित मेहता ",
        member_profile: amitmehata,
        member_post: "सहसचिव",
        department: "Sales",
        mobile_number: "9826099440",
        whatsapp_number: "9826099440"
    },

    {
        member_name: "मोहन धनोतिया",
        member_profile: mohandhanotiya,

        member_post: "उत्सव समिति-संयोजक ",
        department: "Quality Assurance",
        mobile_number: "9644591070",
        whatsapp_number: "9644591070"
    },
    {
        member_name: "श्रीमती हेमलता प्रहलाद दास घाटिया",
        member_profile: hemlataprahlad,
        member_post: "उत्सव समिति",
        department: "Human Resources",
        mobile_number: "9424810455",
        whatsapp_number: "9424810455"
    },

    {
        member_name: "श्री राम मांदलिया",
        member_profile: shreerammandliya,

        member_post: "उत्सव समिति",
        department: "Quality Assurance",
        mobile_number: "9425400715",
        whatsapp_number: "9425400715"
    }
    ,


    {
        member_name: "निलेश पोरवाल",
        member_profile: nileshporwal,
        member_post: "उत्सव समिति",
        department: "Quality Assurance",
        mobile_number: "9009099599",
        whatsapp_number: "9009099599"
    },
    {
        member_name: "अजय अशोक कुमार पोरवाल (टीटू अतिथी)",
        member_profile: ajayashok,
        member_post: "उत्सव समिति",
        department: "Operations",
        mobile_number: "9827072093",
        whatsapp_number: "9827072093"
    },
    {
        member_name: "अरुण पोरवाल",
        member_profile: arunporwal,
        member_post: "उत्सव समिति",
        department: "Quality Assurance",
        mobile_number: "9826444677",
        whatsapp_number: "9826444677"
    },
    {
        member_name: "महेश पोरवाल",
        member_profile: maheshporwal,
        member_post: "उत्सव समिति",
        department: "Quality Assurance",
        mobile_number: "9424011010",
        whatsapp_number: "9424011010"
    },
    {
        member_name: "श्रीमती कमल सुरेशचंद्र घरिया",
        member_profile: kamalghariya,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "7440449011",
        whatsapp_number: "7440449011"
    }
    ,

    {
        member_name: "देवेन्द्र मेहता (नन्दु जी अंगुठी वाला)",
        member_profile: devendramehata,
        member_post: "कार्यकारिणी सदस्य",
        department: "Product Management",
        mobile_number: "9826297987",
        whatsapp_number: "9826297987"
    },
    {
        member_name: "रोहित लेखराज पोरवाल",
        member_profile: rohitporwal,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "9826370896",
        whatsapp_number: "9826370896"
    }
    ,
    {
        member_name: "राजकुमार मेहता,एडवोकेट",
        member_profile: rajkumarmehata,
        member_post: "कार्यकारिणी सदस्य",
        department: "Marketing",
        mobile_number: "9827270656",
        whatsapp_number: "9827270656"
    },

    {
        member_name: "श्यामसुंदर पोरवाल ( राणापुरवाला)",
        member_profile: shamsundarporwal,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "9826053023",
        whatsapp_number: "9826053023"
    }
    ,
    {
        member_name: "गोविन्द गुप्ता ",
        member_profile: govindgupta,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "9826010105",
        whatsapp_number: "9826010105"
    }

    ,
    {
        member_name: "राहुल गोपाल जी धनोतिया (चक्की वाला)",
        member_profile: rahulgopal,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "9826131004",
        whatsapp_number: "9826131004"
    }
    ,

    {
        member_name: "मयंक रत्नावत",
        member_profile: mayankratnawat,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "9926082249",
        whatsapp_number: "9926082249"
    }
    ,


    {
        member_name: "श्रीमती मंजू दीपक धनोतिया",
        member_profile: manjudipak,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "8109609675",
        whatsapp_number: "8109609675"
    }

    ,
    {
        member_name: "डॉक्टर प्रियंका गुप्ता",
        member_profile: priyankagupta,
        member_post: "कार्यकारिणी सदस्य",
        department: "Design",
        mobile_number: "9425901030",
        whatsapp_number: "9425901030"
    },
    {
        member_name: "श्रीमती कविता रमेश पोरवाल (चौमहला वाला)",
        member_profile: kavitaramesh,
        member_post: "कार्यकारिणी सदस्य",
        department: "Quality Assurance",
        mobile_number: "9425352838",
        whatsapp_number: "9425352838"
    }
    ,


































];

// nileshporwal

export default CommitteeMembers;

