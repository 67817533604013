import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// import { isUserLogin } from '../../../service/Services.js';
// import { setUser } from '../../../Redux/authSlicer.js';
import { useDispatch } from 'react-redux';



import { DefaultThem } from '../../matrimonyThems/index.js';
import { HeaderBoarder } from '../../Components/index.js';


export default function ThemDetails() {
    const { them } = useParams();
    const dispatch = useDispatch()

    const text = {
        Default: "Default Them",
        // members: "समाज सदस्य",
        // mandirdarshan: "श्री लक्ष्मी नरसिंह मंदिर दर्शन",
        // rishtey: "विवाह रिश्ते",
        // bookdharmashala: "बुक धर्मशाला",
        // programs: "कार्यक्रम",
        // bloodbank: "ब्लड बैंक",
        // donations: "डोनेशन लागा",
        // achievements: "उपलब्धियां",
        // samajcalender: "समाज कैलेंडर",
        // digitalcard: "डिजिटल कार्ड",
        // promotion: "एड प्रमोशन",
        // complaints: "शिकायतें",
        // invitations: "निमंत्रण",
        // shoksamachar: "शोक समाचार",
        // jobs: "नौकरी",
        // businessprofile: "बिजनेस प्रोफाइल",
        // registration: "रजिस्ट्रेशन",
        // artical: "आर्टिकल",
        // lagahistory: 'समाज लागा विवरण '
    };






    //  कमिटी
    //   मेंबर्स
    //   मंदिर दर्शन
    //   विवाह रिश्ते
    //   बुक धर्मशाला
    //   कार्यक्रम
    //   ब्लड बैंक
    //   डोनेशन लागा
    //   उपलब्धियां
    //   समाज कैलेंडर
    //   डिजिटल कार्ड
    //   एड प्रमोशन
    //   शिकायतें
    //   निमंत्रण
    //   शोक समाचार
    //   नौकरी
    //   बिजनेस प्रोफाइल
    //   रजिस्ट्रेशन

    function renderPages(them) {
        switch (them) {
            case "Defauls": return <DefaultThem />;
            // case "members": return <Members />;
            // case "mandirdarshan": return <Mandirdarshan />;
            // case "rishtey": return <Rishtey />;
            // case "bookdharmashala": return <Bookdharmashala />;
            // case "programs": return <Programs />;
            // case "bloodbank": return <BloodbankPage />;
            // case "donations": return <Donations />;
            // case "achievements": return <Achievements />;
            // case "samajcalender": return <Samajcalender />;
            // case "digitalcard": return <Digitalcard />;
            // case "promotion": return <Promotion />;
            // case "suzhav": return <Suzhav />;
            // case "invitations": return <Invitations />;
            // case "shoksamachar": return <Shoksamachar />;
            // case "jobs": return <Jobs />;
            // case "businessprofile": return <Businessprofile />;
            // case "registration": return <Registration />;
            // case "artical": return <Artical />;
            // case "profileEdit": return <MemberProfileEditForm />;
            // case "careerGuidance": return <CareerGuidance />;
            // case "lagahistory": return <LagaHistory />;
            default:
                break;
        }
    }
    return (
        <>

            <HeaderBoarder heading={text[them]} />
            {renderPages(them)}

            {/* <AdminDetails /> */}
        </>
    );
}
