import React, { useEffect, useState } from 'react'
import { LagahistoryCard } from '../../../cards'
import AdminButton from '../../../Forms/AdminButtons'
import { getLagabyMemberCode } from '../../../service/Services';
import LoadingModal from '../../../Forms/LoadingModel';

export default function LagaHistory() {
    const [yourLagas, setYourLagas] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await getLagabyMemberCode()
                if (res?.success) {
                    setYourLagas(res?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])




    return (
        <>
            <LoadingModal show={loading} text={'Loading...'} />
            <AdminButton text="Creat laga" className=" bg-blue-950 text-white" url="/admin/creatLaga" />
            <div className=' flex justify-center flex-wrap gap-3 my-5'>
                {yourLagas.map((laga, index) => (
                    <span key={index}>
                        <LagahistoryCard laga={laga} />
                    </span>
                ))}

            </div>
        </>
    )
}
