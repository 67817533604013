import React from 'react'
import { mandirLogo, mandir } from '../assets/MandirImg'

export default function mandirCard() {
    return (
        <div className=' flex flex-wrap justify-center'>
            {/* <div className='h-100 m-2    min-w-[280px] max-w-[300px] p-5 border-r-5 border  rounded-xl shadow-2xl bg-white text-black  '>
                <img src={mandirLogo} />
            </div> */}
            <div className='h-100 m-2    min-w-[280px] max-w-[300px] p-2 border-r-5 border  rounded-xl shadow-2xl bg-white text-black  '>
                <img src={mandir} />
            </div>
        </div>
    )
}
