import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pagination: {},
};

const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPagination(state, action) {
            state.pagination = action.payload;
        },
        getPagination(state) {
            return state.pagination;
        },
    },
});

export const { setPagination, getPagination } = paginationSlice.actions;
export default paginationSlice.reducer;
