import React, { useEffect, useState } from 'react'
import { getDonations } from '../../../service/Services';
import { DonationCard } from '../../../cards';
import AdminButton from '../../../Forms/AdminButtons';

export default function Donations() {
    const [donations, setDonations] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await getDonations()
                if (res?.success) {
                    setDonations(res?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    if (loading) {
        return <p className=' font-bold'>Loading...</p>
    }
    return (
        // <div><DonationForm /></div>
        <div>
            <AdminButton text="नया दान जोड़ें" className=" bg-blue-950 text-white" url="/admin/addDonation" />

            <div className=' w-full text-end p-4'>
                {/* <Link className=' btn bg-blue-950 text-white' to={'/dashboard/addDonation'}>Add Donation</Link> */}

                <div className='flex  flex-wrap gap-4 justify-center '>
                    {donations.map((donation, index) => (
                        <div className='w-[350px]' key={index}><DonationCard donation={donation} /></div>
                    ))}

                </div>
                {donations.length == 0 && <h1>No Event In this Month</h1>}

            </div>
        </div>
    )
}
