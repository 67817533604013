import React, { useEffect, useState } from 'react'
import SamajCalenderForm from '../../../Forms/samajCalenderForm'
import { Link } from 'react-router-dom'
import { getSamajcalenderEvent } from '../../../service/Services'
import { SamajCalenderCard } from '../../../cards'

import AdminButton from '../../../Forms/AdminButtons'

export default function Samajcalender() {
    const [loading, setLoading] = useState(false);
    const [samajEvents, setSamajEvents] = useState([])


    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await getSamajcalenderEvent()
                console.log(res)
                if (res?.success) {
                    setSamajEvents(res?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }

        })()
    }, [])

    if (loading) {
        return <p className=' font-bold'>Loading...</p>
    }
    return (
        <div>
            <AdminButton text="कार्यक्रम जोड़ें" className=" bg-blue-950 text-white" url="/admin/calender" />

            <div className=' w-full text-end p-4'>
                {/* <Link className=' btn bg-blue-950 text-white' to={'/dashboard/calender'}>Add Event</Link> */}
                {/* <SamajCalenderForm /> */}
                <div className='flex  flex-wrap gap-4 justify-center '>
                    {samajEvents.map((event, index) => (
                        <div className='w-[350px]' key={index}><SamajCalenderCard event={event} /></div>
                    ))}

                </div>
                {samajEvents.length == 0 && <h1>No Event In this Month</h1>}
                {/* <SamajCalenderCard event={ } /> */}
            </div>
        </div>
    )
}
