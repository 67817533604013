import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FormHeading } from '../../Forms'
import GodLogo from "../../assets/final cards for mayur/committee cards/samajbutton.png"
import { useParams } from 'react-router-dom'
import { memberScanByQr } from '../../service/Services'
import LoadingModal from '../../Forms/LoadingModel'
import { MemberTable } from '../../Components/PopupModel'

import callporwal from "../../assets/membercard/callbutton.png"
import chatporwal from "../../assets/membercard/chatbutton.png"
import report from "../../assets/membercard/reportbutton.png"
import { Domain } from '../../ApiConfig'
export default function MemberQrViewPage() {
    const [scanedProfile, setProfile] = useState(null)
    const [loading, setLoading] = useState(false)

    const { memberShipCode } = useParams()

    useEffect(() => {
        const fetchScannedMember = async () => {
            try {
                setLoading(true)
                const scannedMember = await memberScanByQr({ memberShipCode });

                console.log("scannedMember", scannedMember)

                if (scannedMember?.success) {
                    setProfile(scannedMember?.data)
                }
                // Do something with the scannedMember, e.g., update state
            } catch (error) {
                console.error('Error fetching scanned member:', error);
                // Handle error, e.g., show an error message
            } finally {
                setLoading(false)
            }
        };

        fetchScannedMember();
    }, [memberShipCode]);

    console.log('memberShipCode', memberShipCode)

    return (
        <>
            <LoadingModal show={loading} text={'Scanning...'} />
            {scanedProfile && <div className="     flex justify-center items-center  inset-0 overflow-y-auto overflow-x-hidden   bg-black bg-opacity-50 ">
                <div className='w-[950vw] h-[100%] p-4  comittee-card-bg-color-bottom shadow-2xl'>
                    {/* <span onClick={togglePopup} className=' w-full text-2xl text-white font-semibold cursor-pointer flex justify-end hover:text-yellow-400'>&#10005;</span> */}
                    <div className=' w-full flex justify-between'>
                        <img className=" h-10 w-10  rounded-full top-5 border-2  bg-gray-50" src={GodLogo} alt="GodLogo" loading="lazy" />
                        <h1 className=" text-yellow-400 font-bold  ">श्री जांगड़ा पोरवाल पंचायती सभा इंदौर</h1>
                    </div>
                    <div className=' relative w-full flex flex-col items-center  h-20 rounded-t-xl'>
                        {/* <h1 className=" text-orange-500  ">{{scanedProfile?.name}</h1> */}
                        <h1 className=" text-white  font-bold  "> Member code :{scanedProfile?.membershipCode}</h1>
                        <img
                            className="h-20 w-20 absolute left-1/3 top-1/2 md:left-[45%] rounded-full z-[99999] border-4 bg-gray-50"
                            src={`${Domain}${scanedProfile?.profilePhoto?.replace(" ", "%20")}`}
                            alt="Profile Photo"
                            loading="lazy"
                        />
                    </div>
                    <div className=' mt-10'>
                        <p className=' text-center  text-white font-bold rounded-md '>{scanedProfile?.name}</p>
                    </div>
                    {/* <div className=' flex flex-col my-3 items-center   justify-start    text-[12px] font-bold text-white'> */}

                    {/* <div className=' flex flex-col   justify-start    text-[14px] font-bold text-black'> */}
                    <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Personal Information'} />

                    <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Membership Code / मेंबरशिप कोड:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.membershipCode}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Name / नाम:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.name}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Surname / उपनाम:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.surname}</p>
                        </div>
                        {scanedProfile?.mobileNo && (
                            <>
                                <div className=''>
                                    <p className='text-start text-[15px] font-extrabold text-black  '>Mobile no / मोबाइल नंबर:</p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.mobileNo}</p>
                                </div>
                                <div className=''>
                                    <p className='text-start text-[15px] font-extrabold text-black  '>Whatsapp No / व्हाट्सएप नंबर:</p>
                                    <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.whatsappNo}</p>
                                </div>
                            </>
                        )}
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Father’s Name / पिता का नाम:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.fatherName}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Mother’s Name / माताजी का नाम:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.motherName}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Gotra / गोत्र:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.gotra}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Birth Date / जन्म तिथि:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.birthDate}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Email id / ईमेल आईडी:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.email}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Gender:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.gender}</p>
                        </div>
                        <div className=''>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Blood Group / ब्लड ग्रुप:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.bloodGroup}</p>
                        </div>
                    </div>

                    <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Address'} />
                    <div className='  grid grid-cols-1    rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                        <div className=' '>
                            <p className='text-start text-[15px] font-extrabold text-black  ' >Permanent Address / स्थाई पता: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.permanentAddress}</p>
                        </div>

                        <div className='   '>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Current Address / वर्तमान पता: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.currentAddress}</p>
                        </div>
                        <div className='  '>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Current Address Area / पता एरिया:</p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.currentAddressArea}
                            </p>
                        </div>

                        <div className='   '>
                            <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>City name / शहर का नाम: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.city.name}</p>
                            </div>
                        </div>
                        <div className='   '>
                            <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>State name / स्टेट का नाम: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.State.name}</p>
                            </div>
                        </div>
                        <div className='   '>
                            <div className=''>  <p className='text-start text-[15px] font-extrabold text-black  '>Country name / देश का नाम: </p>
                                <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.country.name}</p>
                            </div>
                        </div>
                        <div className=' '>  <p className='text-start text-[15px] font-extrabold text-black  '>Pincode / पिनकोड: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.pincode}</p>
                        </div>
                    </div>


                    <FormHeading style={'bg-yellow-500 text-center rounded-md'} heading={'Occupation & Education'} />
                    <div className='  grid grid-cols-1 rounded-md shadow-2xl  p-2 md:grid-cols-2 text-[14px] bg-white     md:gap-y-4 md:gap-x-10'>
                        <div className=' '>
                            <p className='text-start text-[15px] font-extrabold text-black  '>Education Qualification / शैक्षणिक योग्यता: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.educationQualification}</p>
                        </div>
                        <div className='  '>  <p className='text-start text-[15px] font-extrabold text-black  '>Occupation / व्यवसाय: </p>
                            <p className='font-semibold text-[12px] text-blue-600'>{scanedProfile?.occupation}</p>
                        </div>
                    </div>
                    <MemberTable memberFamilyDetails={scanedProfile?.memberFamilyDetails} />

                    <div className='flex justify-center my-5 items-baseline  '>
                        <div className='member-card-bg-bottom flex justify-center  gap-4 px-6 py-2  rounded-full shadow-2xl    items-end '>
                            {/* <img className='w-8 h-8' src={QR} alt="QR Code" /> */}
                            {scanedProfile?.mobileNo ? <>
                                <img onClick={() => window.location.href = `tel:${scanedProfile?.mobileNo}`} className='w-8 h-8' src={callporwal} alt="Call" loading="lazy" />
                                <img onClick={() => window.location.href = `whatsapp://send?phone=${scanedProfile?.whatsappNo}`} className='w-8 h-8' src={chatporwal} alt="Chat" loading="lazy" />
                            </> :
                                <>
                                    <img className='w-8 h-8' src={callporwal} alt="Call" loading="lazy" />
                                    <img className='w-8 h-8' src={chatporwal} alt="Chat" loading="lazy" />
                                </>
                            }
                            {/* <img className='w-8 h-8' src={viewporwal} alt="View" loading="lazy" /> */}
                            <img className='w-8 h-8' src={report} alt="View" loading="lazy" />
                        </div>
                    </div>

                </div>

            </div>}
        </>
    )
}
