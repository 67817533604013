import React, { useId } from "react";

const Input = ({ label, type = "text", className = "", required = "", ...props }, ref) => {
    const id = useId();

    // console.log("props")

    return (
        <div className="w-full">
            {label && (
                <label htmlFor={id} className={` capitalize block ${required} text-sm font-extrabold  leading-6 text-gray-900}`}>
                    {label}
                </label>
            )}
            <div className="mt-1">
                <input
                    type={type}
                    ref={ref}
                    id={id}
                    className={`${className} block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                    {...props}
                />
            </div>
        </div>
    );
};

export default React.forwardRef(Input);
