import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    MonthView,
    Toolbar,
    DateNavigator,
    Appointments,
    TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { getDharmashala } from '../service/Services';

// import { appointments } from '../../../demo-data/month-appointments'; // Assuming this is your appointment data
export let appointments = [
    {
        title: 'Website Re-Design Plan',
        startDate: new Date(2024, 6, 23, 9, 30),
        endDate: new Date(2024, 6, 23, 11, 30),
    }, {
        title: 'Book Flights to San Fran for Sales Trip',
        startDate: new Date(2024, 6, 23, 12, 0),
        endDate: new Date(2024, 6, 23, 13, 0),
    }, {
        title: 'Install New Router in Dev Room',
        startDate: new Date(2024, 6, 23, 14, 30),
        endDate: new Date(2024, 6, 23, 15, 30),
    }, {
        title: 'Approve Personal Computer Upgrade Plan',
        startDate: new Date(2024, 6, 24, 10, 0),
        endDate: new Date(2024, 6, 24, 11, 0),
    }, {
        title: 'Final Budget Review',
        startDate: new Date(2024, 6, 24, 12, 0),
        endDate: new Date(2024, 6, 24, 13, 35),
    }, {
        title: 'New Brochures',
        startDate: new Date(2024, 6, 24, 14, 30),
        endDate: new Date(2024, 6, 24, 15, 45),
    }, {
        title: 'Install New Database',
        startDate: new Date(2024, 6, 25, 9, 45),
        endDate: new Date(2024, 6, 25, 11, 15),
    }, {
        title: 'Approve New Online Marketing Strategy',
        startDate: new Date(2024, 6, 25, 12, 0),
        endDate: new Date(2024, 6, 25, 14, 0),
    }, {
        title: 'Upgrade Personal Computers',
        startDate: new Date(2024, 6, 25, 15, 15),
        endDate: new Date(2024, 6, 25, 16, 30),
    }, {
        title: 'Customer Workshop',
        startDate: new Date(2024, 6, 26, 11, 0),
        endDate: new Date(2024, 6, 26, 12, 0),
    }, {
        title: 'Prepare 2015 Marketing Plan',
        startDate: new Date(2024, 6, 26, 11, 0),
        endDate: new Date(2024, 6, 26, 13, 30),
    }, {
        title: 'Brochure Design Review',
        startDate: new Date(2024, 6, 26, 14, 0),
        endDate: new Date(2024, 6, 26, 15, 30),
    }, {
        title: 'Create Icons for Website',
        startDate: new Date(2024, 6, 27, 10, 0),
        endDate: new Date(2024, 6, 27, 11, 30),
    }, {
        title: 'Upgrade Server Hardware',
        startDate: new Date(2024, 6, 27, 14, 30),
        endDate: new Date(2024, 6, 27, 16, 0),
    }, {
        title: 'Submit New Website Design',
        startDate: new Date(2024, 6, 27, 16, 30),
        endDate: new Date(2024, 6, 27, 18, 0),
    }, {
        title: 'Launch New Website',
        startDate: new Date(2024, 6, 26, 12, 20),
        endDate: new Date(2024, 6, 26, 14, 0),
    }, {
        title: 'Website Re-Design Plan',
        startDate: new Date(2024, 6, 16, 9, 30),
        endDate: new Date(2024, 6, 16, 15, 30),
    }, {
        title: 'Book Flights to San Fran for Sales Trip',
        startDate: new Date(2024, 6, 16, 12, 0),
        endDate: new Date(2024, 6, 16, 13, 0),
    }, {
        title: 'Install New Database',
        startDate: new Date(2024, 6, 17, 15, 45),
        endDate: new Date(2024, 6, 18, 12, 15),
    }, {
        title: 'Approve New Online Marketing Strategy',
        startDate: new Date(2024, 6, 18, 12, 35),
        endDate: new Date(2024, 6, 18, 14, 15),
    }, {
        title: 'Upgrade Personal Computers',
        startDate: new Date(2024, 6, 19, 15, 15),
        endDate: new Date(2024, 6, 20, 20, 30),
    }, {
        title: 'Prepare 2015 Marketing Plan',
        startDate: new Date(2024, 6, 20, 20, 0),
        endDate: new Date(2024, 6, 20, 13, 30),
    }, {
        title: 'Brochure Design Review',
        startDate: new Date(2024, 6, 20, 14, 10),
        endDate: new Date(2024, 6, 20, 15, 30),
    }, {
        title: 'Vacation',
        startDate: new Date(2024, 5, 22),
        endDate: new Date(2024, 6, 2),
    }, {
        title: 'Vacation',
        startDate: new Date(2024, 6, 28),
        endDate: new Date(2024, 7, 7),
    },
];
const CustomDateNavigator = ({ currentDate }) => {
    const date = new Date(currentDate);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return (
        <div>
            <span>{month} {year}</span>
        </div>
    );
};
// function customEventRenderer(event) {
//     const backgroundColor = 'red'; // Adjust colors as needed

//     return (
//         <div style={{ backgroundColor }}>
//             {/* Event content - title, time, etc. */}
//             {event.title} ({event.status})
//         </div>
//     );
// }
const BookingCalendar = ({ isNewBookindAdded }) => {
    // Use useState hook to manage the appointment data
    const [data, setData] = useState(appointments);
    function convertBookingToCalendarEvent(bookingData) {
        // Extract relevant dates from checkInDateTime and checkOutDateTime
        const checkInDate = new Date(bookingData.checkInDateTime);
        const checkOutDate = new Date(bookingData.checkOutDateTime);
        // Calculate booking duration in days (consider adjusting for hours if needed)
        const bookingDurationDays = parseInt(bookingData.bookingDurationDaysAndhours.split(' ')[0]);

        // Create the calendar event object
        const calendarEvent = {
            title: bookingData.name, // Use booking name as title
            startDate: checkInDate,
            endDate: checkOutDate, // Add booking duration days to checkOutDate
            // endDate: new Date(checkOutDate.setDate(checkOutDate.getDate() + bookingDurationDays)), // Add booking duration days to checkOutDate
            // Properties related to booking status
            //   isConfirmed: bookingData.confirmBy !== 0,
            // status: bookingData.confirmBy === 0 ? 'Pending' : 'Confirmed',
            // Optional properties to customize event appearance (consider using a library)
            style: {
                height: '30px', // Adjust height as needed
                width: '10px', // Adjust width as needed
                // backgroundColor: bookingData.confirmBy === 0 ? '#f0ad4e' : '#5cb85c', // Adjust colors as needed
            },
        };

        return calendarEvent;
    }
    useEffect(() => {
        (async () => {
            // setLoading(true)
            try {
                const res = await getDharmashala()
                console.log(res?.data)
                if (res?.success) {
                    // setAchivement(res?.data)
                    const BokkingDates = res?.data.map((data, index) => {
                        return convertBookingToCalendarEvent(data)
                    })
                    setData(BokkingDates)
                }
            } catch (error) {
                console.log(error)
            } finally {
                // setLoading(false)
            }
        })()
    }, [isNewBookindAdded])

    return (
        <Paper >
            <Scheduler data={data} >
                <ViewState defaultCurrentDate={new Date()} />
                <MonthView />
                <Toolbar />
                <DateNavigator
                    navigatorComponent={({ currentDate }) => (
                        <CustomDateNavigator currentDate={currentDate} />
                    )}
                />
                <TodayButton />
                <Appointments />
            </Scheduler>
        </Paper>
    );
};

export default BookingCalendar;





