import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Home/header'
import Footer from '../Components/Footer'

export default function PublickRout() {
    return (
        <>
            {/* <Header /> */}
            <Outlet />
            <Footer />
        </>


    )
}
