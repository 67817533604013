import React, { useEffect, useState } from 'react'
import { AdminButtons, ShokSamacharForm } from "../../../Forms"
import { Link } from 'react-router-dom'
import { getCitysOnInput, searchNewsForNewsPage, shockNewNotification } from '../../../service/Services'
import { NewsCard } from '../../../Components';
import { CityDropDown, Input, SearchCriteria } from '../../../FormsInputes.js';
import AdminButton from '../../../Forms/AdminButtons.js';

export default function Shoksamachar() {
    const [criteria, setCriteria] = useState({ name: "", city: "", event: "", entryDatetime: "" })
    const [byName, setByName] = useState([])
    const [city, setCity] = useState([])
    const [event, setEvent] = useState(['शोक समाचार', 'उठावना'])
    const [isLoading, setLoading] = useState(false);
    // searchNewsForNewsPage
    const [news, setNews] = useState([]);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const res = await shockNewNotification()
    //             console.log(res)
    //             if (res?.success) {
    //                 setNews(res?.data)
    //             }

    //         } catch (error) {
    //             console.log(error)
    //         }

    //     })()
    // }, [])

    useEffect(() => {
        setLoading(true)
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const { name, city, event, entryDatetime } = criteria;
                console.log('criteria', criteria)

                const newsData = await searchNewsForNewsPage({ name, city, event, entryDatetime })
                // const newsData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
                if (newsData?.success) {
                    const data = newsData?.data || [];
                    setNews(pre => [...pre, ...data])
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            // source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [criteria])

    useEffect(() => {
        setNews(pre => [])
    }, [criteria])

    // useEffect(() => {
    //     let delayDebounceFn;
    //     console.log(criteria)
    //     if (criteria.State)
    //         delayDebounceFn = setTimeout(async () => {
    //             try {
    //                 const newsData = await getCitysOnInput(criteria)
    //                 // const newsData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
    //                 console.log(newsData)
    //                 if (newsData?.success) {
    //                     const data = newsData?.data || [];
    //                     console.log(data)
    //                     // setNews(pre => [...pre, ...data])
    //                     // _id: '6607d014902b20b2e4ccc034',
    //                     //     cityID: 191,
    //                     //         cityName: 'Dindori',
    //                     //             stateID: 19,
    //                     //                 countryID: 1,
    //                     //                     __v: 0
    //                     // setCity(pre)

    //                     const uniqueCities = Array.from(new Set(data.map(city => city.cityID)))
    //                         .map(cityId => {
    //                             const city = data.find(city => city.cityID === cityId);
    //                             return {
    //                                 name: city.cityName,
    //                                 id: city.cityID,
    //                                 ...city
    //                             };
    //                         });

    //                     console.log(uniqueCities)
    //                     setCity(uniqueCities);
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching data:', error);
    //             } finally {
    //                 // setLoading(false)
    //             }
    //         }, 500);

    //     return () => {
    //         clearTimeout(delayDebounceFn);
    //         // source.cancel('Request canceled because a new request was made');
    //     };
    // }, [criteria.city])




    useEffect(() => {
        const uniqueNames = [...new Set(news.map(member => member.personName))];
        setByName(uniqueNames);
        // console.log(uniqueNames)
        // const uniqueCities = Array.from(new Set(news.map(member => member.city.id)))
        //     .map(cityId => news.find(member => member.city.id === cityId).city);
        // setCity(uniqueCities);

        // const uniqueSurnames = [...new Set(news.map(member => member.surname))];
        // setSurname(uniqueSurnames);
        // const uniqueGotras = [...new Set(news.map(member => member.gotra))];
        // setGotra(uniqueGotras);
        // const uniqueOccupations = [...new Set(news.map(member => member.occupation))];
        // setOccupation(uniqueOccupations);
    }, [news.length])






    return (
        <div>
            <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">
                <div className="   ">
                    {/* name */}
                    <SearchCriteria
                        options={byName}
                        search={'name'}
                        id={'name'}
                        setOptions={setCriteria}
                        label={"नाम "}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}
                    />
                </div>
                {/* city */}
                <div className="w-100  ">
                    <CityDropDown
                        options={city}
                        setCity={setCity}
                        search={'city'}
                        id={'city'}
                        setOptions={setCriteria}
                        label={"शहर"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                    {/* // Pass the register function as a prop */}

                </div>

                {/* event */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={event}
                        search={'event'}
                        id={'event'}
                        setOptions={setCriteria}
                        label={"आयोजन"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>
                {/* Date */}

                <div className="w-[90vw] md:w-[15vw]">
                    <Input
                        label="तारीख"
                        placeholder="Date"
                        type="date"
                        className="h-9"

                    // {...register("bookingDate", { required: "इस फ़ील्ड की भरना ज़रूरी है" })}
                    // onChange={(e) => handleChange("bookingDate", e)}
                    />
                    {/* {errors.bookingDate && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.bookingDate.message}</p>} */}
                </div>
            </div>
            {/* <div className=' w-full text-end p-4'>
                <Link className=' btn bg-blue-950  text-white' to={'/dashboard/shocknews'}>Add Shok News</Link>
            </div> */}
            <AdminButtons text="नया समाचार" className=" bg-blue-950 text-white" url="/admin/addshocknews" />

            <div className='flex  flex-wrap gap-4 justify-center '>
                {news.map((news, index) => (
                    <div className='w-[350px]' key={news.newsid}><NewsCard news={news} /></div>
                ))}
            </div>
        </div>
    )
}
