import React from 'react'
import { Link } from 'react-router-dom'
import { FormButton, PublicButtons } from '../../../Forms'

export default function Suzhav() {
    return (
        <div>
            {/* <div className=' w-full text-end p-4'>
                <Link className=' btn bg-blue-950 text-white' to={'/dashboard/Suzhav'}>Add suzhav</Link>
            </div> */}

            <PublicButtons text="नया सुझाव जोड़े" className=" bg-blue-950 text-white" url="/dashboard/newSuzhav" />
        </div>
    )
}
