import React from 'react'
import MandirCard from '../../../Components/mandirCard'
import mamdirBg from "../../../assets/MandirImg/mandirBg.jpg"

export default function Mandirdarshan() {
    return (
        <div className='w-screen h- overflow-y-auto bg-cover bg-no-repeat' style={{ backgroundImage: `url(${mamdirBg})`, backgroundAttachment: 'fixed' }}>
            <MandirCard />
        </div>
    )
}
