import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from './Home/Home';
import { DashHome, DashDetail } from './Dashboard';
import { Footer, Header } from '../Components';
import { RegistrationForm, CommitteeForm, ShokSamacharForm, MatrimonyForm, Achivementform, Suzhavform, DonationForm, DharmashalaBookingFrom, CreatLaga } from '../Forms';
import LandingPage from './Home/LandingPage';
import { LoginContext } from '../App';
import useSessionStorage from '../CostomHooks/useSession';
import HomeRegistationForm from './Home/homeRegistation';
import { useLayoutEffect } from 'react';
import PrivateRoute from './PrivateRoute';

import PrivacyPolicy from './Home/PrivacyPolicy';
import PublickRout from './PublickRout';
import Samajcalender from './Dashboard/DashPages/Samajcalender';
import SamajCalenderForm from '../Forms/samajCalenderForm';
import { AdminDetails, UserManagement } from './admin';
import AdminRoute from "./AdminRoute"
import Bookdharmashala from './Dashboard/DashPages/Bookdharmashala';
import MemberQrViewPage from './Home/memberQrViewPage';
import AdminHomePage from './admin/AdminHomePage';
import ThemDetails from './Thems/ThemDetails';
import MatrimonyQrView from './Thems/matrimonyQrView';
import MatrimonyShearQrView from './Thems/MatrimonyShearQrView';




export default function Nav() {
    // const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('access_token'));

    const isAuthenticated = useSelector(state => state?.auth?.isLogin);
    console.log("isAuthenticated", isAuthenticated)

    return (
        <>
            {/* <PageRefesh /> */}
            <Routes>
                <Route element={<PublickRout />}>
                    <Route path="/" exact element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Home />} />
                    {/* <Route path="/Privacypolicy" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <PrivacyPolicy />} /> */}
                    <Route path="/first/registration" exact element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <HomeRegistationForm />} />
                    <Route path="*" exact element={<LandingPage />} />
                </Route>
                {/* Private routes accessible only when authenticated */}
                <Route>
                    <Route path="/Privacypolicy" exact element={<PrivacyPolicy />} />
                    <Route path="/matrimony/registration" exact element={<MatrimonyForm />} />
                    <Route path="/memberQr/:memberShipCode/" exact element={<MemberQrViewPage />} />
                    <Route path="/matrimonyQr/:matCode" element={<MatrimonyQrView />} />
                    <Route path="/QR/:token" element={<MatrimonyShearQrView />} />
                </Route>


                {/* Admin routes accessible only for admin */}
                <Route path="/admin" element={<AdminRoute />}>
                    <Route path=":pages" exact element={<AdminDetails />} />
                    <Route path="" element={<AdminHomePage />} />
                    {/* <Route path=":pages" element={<UserManagement />} /> */}
                    <Route path="addshocknews" element={<ShokSamacharForm />} />
                    <Route path="addDonation" element={<DonationForm />} />
                    <Route path="calender" element={<SamajCalenderForm />} />
                    <Route path="achivement" element={<Achivementform />} />
                    <Route path="creatLaga" element={<CreatLaga />} />
                    {/* <Route path="dharmashalaBooking" element={<DharmashalaBookingFrom />} /> */}
                    {/* <Route path="matrimony/registration" element={<MatrimonyForm />} /> */}
                </Route>


                <Route path="/" element={<PrivateRoute />}>
                    {/* /dashboard/Suzhav */}
                    <Route path="/Committee" exact element={<CommitteeForm />} />
                    <Route path="/dashboard" exact element={<DashHome />} />
                    <Route path="/dashboard/:section" exact element={<DashDetail />} />
                    <Route path="/dashboard/dharmashalaBooking" element={<DharmashalaBookingFrom />} />
                    <Route path="/dashboard/newSuzhav" element={<Suzhavform />} />
                    <Route path="/them/:them" element={<ThemDetails />} />

                </Route>

            </Routes>
        </>
    );
}
